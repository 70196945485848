<template>
  <div>
    <v-row>
      <v-col md="12" lg="12" xl="12" cols="12" v-if="dialog == true">
        <loadingComponent
          @closeDialog="dialog = $event"
          :messageDialogeProps="messageDialogeError"
          :dialogProps="dialog"
          :loadingProps="loading"
          :errorProps="error"
        />
      </v-col>
    </v-row>
    <v-row v-if="vraiSiret == true">
      <v-col cols="12" sm="12" md="12" lg="12" xl="12">
        <v-subheader class="ma-0 pa-0">Score d'entreprise:</v-subheader>
        <v-divider class="ma-0 pa-0"></v-divider>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <div>
          <v-card-title class="justify-start">
            <v-avatar class="mr-2" size="30" rounded>
              <img
                alt="credit safe score"
                src="../../../assets/images/logos/logo-credit-safe.png"
              />
            </v-avatar>
            creditsafe :
            <span class="ml-2 font-weight-black">
              {{
                isNaN(this.scoreCreditSafe)
                  ? "info manquante"
                  : this.scoreCreditSafe + "/100"
              }}
            </span>
          </v-card-title>
        </div>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <div>
          <v-card-title class="justify-start">
            <v-avatar class="mr-2" height="30" width="61" rounded>
              <img
                alt="credit safe score"
                src="../../../assets/logoElliPro.png"
              />
            </v-avatar>
            Elli Pro :
            <span class="ml-2 font-weight-black">
              {{
                isNaN(this.scoreEllipro)
                  ? "info manquante"
                  : this.scoreEllipro + "/10"
              }}
            </span>
          </v-card-title>
        </div>
      </v-col>
    </v-row>
    <!-- form de siret -->
    <v-form ref="formeSiret" lazy-validation>
      <v-subheader>Recherche par SIRET ou Raison sociale :</v-subheader>
      <v-divider class="mb-12"></v-divider>
      <v-row>
        <v-col cols="12" sm="11" md="6" lg="6" xl="6">
          <v-combobox
            :search-input.sync="valueRechercheSiret"
            @input="getPropositionSiretOrName"
            v-model="valueRechercheSiret"
            outlined
            :rules="[rules.required(`un siret ou une raison sociale valide`)]"
            :items="listSociete"
            :return-object="true"
            label="Siret ou Raison sociale"
            @keydown.enter.prevent="getPropositionSiretOrName"
          ></v-combobox>
        </v-col>
        <v-col cols="12" sm="8" md="6" lg="6" xl="6">
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="6"
              lg="6"
              xl="6"
              class="d-flex justify-content-center"
            >
              <v-btn
                depressed
                elevation="1"
                large
                @click="getListeCompteurs"
                class="responsive-margin text-white"
                :color="$colors[0]"
                width="100%"
                height="50px"
              >
                Rechercher
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>

    <!--form details compteur -->
    <v-form
      ref="formGeneral"
      lazy-validation
      v-if="vraiSiret == true || isHistorique == true"
    >
      <v-row>
        <v-col cols="12" sm="11" md="6" lg="6" xl="6">
          <v-form
            ref="formGetConsommation"
            lazy-validation
            v-if="vraiSiret == true || isHistorique == true"
          >
            <v-select
              required
              outlined
              multiple
              v-model="listCompteurSelected"
              :items="listCompteur"
              label="Numéro de compteur"
              :rules="[$rules.required, validateSelectionCompteur]"
            ></v-select>
          </v-form>
        </v-col>
        <v-col cols="12" sm="8" md="6" lg="6" xl="6">
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="6"
              lg="6"
              xl="6"
              class="d-flex justify-content-center"
            >
              <v-btn
                :disabled="listCompteurSelected.length == 0 ? true : false"
                v-if="listCompteur.length > 0"
                @click="getCompteurData"
                elevation="1"
                depressed
                large
                :color="$colors[0]"
                class="responsive-margin text-white"
                width="100%"
                height="50px"
              >
                Recuperer Consommations
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="6"
              lg="6"
              xl="6"
              class="d-flex justify-content-center"
            >
              <v-btn
                v-if="enedisRecherche"
                @click="openModalFornisseurActuelleFnc"
                elevation="1"
                depressed
                large
                :color="$colors[0]"
                class="responsive-margin text-white"
                width="100%"
                height="50px"
              >
                <v-icon left>{{
                  openModalFornisseurActuelle ? "mdi-minus" : "mdi-plus"
                }}</v-icon>
                Fournisseur
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-for="PDL in compteurPDL" :key="PDL">
        <v-col cols="6">
          <v-select
            required
            outlined
            v-model="listCompteurSelected[PDL]"
            :items="listCompteur"
            :label="`Numéro de compteur ${PDL + 1}`"
            :rules="[rules.required]"
          ></v-select>
        </v-col>
      </v-row>
      <div v-if="listDonnerCotation.length > 0">
        <v-subheader class="ma-0">Compteurs :</v-subheader>
        <v-divider class="ma-0 pa-0"></v-divider>
        <small style="font-size: 12px; color: gray">
          Toutes les consommations sont exprimées en MWh.</small
        >
        <div class="table-compteurs-wrapper">
          <table class="my-4">
            <thead>
              <tr>
                <th
                  style="
                    border: 1px solid gray;
                    width: 12vw;
                    height: 50px;
                    background-color: #103a5e;
                    color: white;
                    text-align: center;
                  "
                  v-for="header in listHeaders"
                >
                  {{ header }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                style="text-align: center"
                v-for="compteurLine in listDonnerCotation"
              >
                <td style="border: 1px solid gray">
                  {{ compteurLine.numCompteur }}
                </td>
                <td style="border: 1px solid gray">
                  <v-select
                    class="pa-4"
                    v-model="compteurLine.optionTarifaire"
                    @change="changeOptionTarifaire(compteurLine)"
                    :items="optionsTarifaires"
                    :rules="[required('Option Tarifaire')]"
                    :disabled="
                      compteurLine.resultatEnedisTechnique &&
                      compteurLine.optionTarifaire != null
                    "
                    outlined
                    dense
                    hide-details
                  />
                  <v-radio-group
                    v-if="
                      (compteurLine.optionTarifaire == 'BTINFMU4' ||
                      compteurLine.optionTarifaire == 'BTINFCU4') && 
                      compteurLine.typeTraitementCompteur != 'simple'
                    "
                    @change="
                      changeOptionCalculeBtinf(
                        compteurLine,
                        compteurLine.parametreCompteur
                      )
                    "
                    v-model="compteurLine.parametreCompteur"
                    row
                    class="smallradio"
                  >
                    <v-radio label="C4" value="C5C4"></v-radio>
                    <v-radio label="HP/HC" value="C5HP"></v-radio>
                    <v-radio label="Base" value="C5BASE"></v-radio>
                  </v-radio-group>
                </td>
                <td style="border: 1px solid gray">
                  <v-text-field
                    class="pa-4"
                    required
                    outlined
                    dense
                    v-model="compteurLine.puissanceSouscrite"
                    hide-details
                    :disabled="
                      compteurLine.resultatEnedisTechnique &&
                      compteurLine.puissanceSouscrite != null &&
                      isDisabledMesures(compteurLine)
                    "
                    :rules="[required('la Puisance souscrite')]"
                  ></v-text-field>
                </td>
                <td style="border: 1px solid gray">
                  <v-text-field
                    class="pa-4"
                    v-if="compteurLine.parametreCompteur == 'C5BASE'"
                    type="number"
                    step="0.001"
                    @input="calculeSommeCotation(compteurLine)"
                    required
                    outlined
                    dense
                    v-model="compteurLine.CABASE"
                    hide-details
                    :rules="[required('une consommation (CABASE) valide')]"
                  ></v-text-field>
                  <v-text-field
                    class="pa-4"
                    v-else-if="
                      compteurLine.parametreCompteur == 'C3' ||
                      compteurLine.parametreCompteur == 'C4' ||
                      compteurLine.parametreCompteur == 'C5C4'
                    "
                    type="number"
                    step="0.001"
                    required
                    @input="calculeSommeCotation(compteurLine)"
                    outlined
                    dense
                    v-model="compteurLine.CAHPH"
                    hide-details
                    :rules="[
                      required('une consommation CONSOMMATION (HPH) valide'),
                    ]"
                  ></v-text-field>
                  <v-text-field
                    class="pa-4"
                    v-else-if="compteurLine.parametreCompteur == 'C5HP'"
                    type="number"
                    @input="calculeSommeCotation(compteurLine)"
                    step="0.001"
                    required
                    outlined
                    dense
                    v-model="compteurLine.CAHP"
                    hide-details
                    :rules="[required('une consommation (HP) valide')]"
                  ></v-text-field>
                </td>
                <td style="border: 1px solid gray">
                  <v-text-field
                    v-if="
                      compteurLine.parametreCompteur == 'C3' ||
                      compteurLine.parametreCompteur == 'C4' ||
                      compteurLine.parametreCompteur == 'C5C4'
                    "
                    class="pa-4"
                    type="number"
                    step="0.001"
                    @input="calculeSommeCotation(compteurLine)"
                    required
                    outlined
                    dense
                    v-model="compteurLine.CAHCH"
                    hide-details
                    :rules="[required('une consommation (HCH) valide')]"
                  ></v-text-field>
                  <v-text-field
                    v-else-if="compteurLine.parametreCompteur == 'C5HP'"
                    class="pa-4"
                    type="number"
                    @input="calculeSommeCotation(compteurLine)"
                    step="0.001"
                    required
                    outlined
                    dense
                    v-model="compteurLine.CAHC"
                    hide-details
                    :rules="[required('une consommation (HC) valide')]"
                  ></v-text-field>
                  <span v-else>---</span>
                </td>
                <td style="border: 1px solid gray">
                  <v-text-field
                    v-if="
                      compteurLine.parametreCompteur == 'C3' ||
                      compteurLine.parametreCompteur == 'C4' ||
                      compteurLine.parametreCompteur == 'C5C4'
                    "
                    class="pa-4"
                    type="number"
                    step="0.001"
                    @input="calculeSommeCotation(compteurLine)"
                    required
                    outlined
                    dense
                    v-model="compteurLine.CAHPE"
                    hide-details
                    :rules="[required('une consommation (HPE) valide')]"
                  ></v-text-field>
                  <span v-else>---</span>
                </td>
                <td style="border: 1px solid gray">
                  <v-text-field
                    v-if="
                      compteurLine.parametreCompteur == 'C3' ||
                      compteurLine.parametreCompteur == 'C4' ||
                      compteurLine.parametreCompteur == 'C5C4'
                    "
                    class="pa-4"
                    type="number"
                    step="0.001"
                    required
                    @input="calculeSommeCotation(compteurLine)"
                    outlined
                    dense
                    v-model="compteurLine.CAHCE"
                    hide-details
                    :rules="[required('une consommation (HCE) valide')]"
                  ></v-text-field>
                  <span v-else>---</span>
                </td>
                <td style="border: 1px solid gray">
                  <v-text-field
                    v-if="compteurLine.parametreCompteur == 'C3'"
                    class="pa-4"
                    type="number"
                    step="0.001"
                    @input="calculeSommeCotation(compteurLine)"
                    required
                    outlined
                    dense
                    v-model="compteurLine.CAP"
                    hide-details
                    :rules="[required('conso (P)')]"
                  ></v-text-field>
                  <span v-else>---</span>
                </td>
                <td style="border: 1px solid gray">
                  <v-text-field
                    :disabled="true"
                    class="pa-4"
                    type="number"
                    required
                    outlined
                    dense
                    v-model="compteurLine.sommeConsomation"
                    hide-details
                  ></v-text-field>
                </td>
                <td style="border: 1px solid gray">
                  <p class="mb-0">
                    {{ compteurLine.resultatEnedis.mesuresInfo[0].nbrMonth }}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- marge et dates -->
      <v-row v-if="enedisRecherche == true" class="ma-0 pa-0">
        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="ma-0 pa-0">
          <v-subheader class="ma-0 pa-0">Détails de l'offre :</v-subheader>
          <v-divider class="ma-0 pa-0"></v-divider>
        </v-col>
        <!-- marge -->
        <v-col class="pl-0" cols="11" sm="4" md="3" lg="3" xl="3">
          <v-text-field
            type="number"
            step="0.001"
            max="25"
            min="2"
            required
            outlined
            v-model="marge"
            label="Marge"
            :rules="[required('une marge'), rules.margeMaxMin]"
          ></v-text-field>
        </v-col>
        <!-- La date de début -->
        <v-col class="pl-0" cols="11" sm="4" md="3" lg="3" xl="3">
          <v-text-field
            type="date"
            outlined
            :rules="[
              required('une date de début du contrat valide'),
              isValidDate('DD/MM/YYYY', minDebutContrat, maxDateDebutContrat),
              isRangeDateValide('Début'),
            ]"
            label="Début de Contrat"
            v-model="dateDebut"
            :min="minDebutContrat"
            :max="maxDateDebutContrat"
          />
        </v-col>

        <!-- La date de fin -->
        <v-col class="pl-0" cols="11" sm="4" md="3" lg="3" xl="3">
          <v-text-field
            type="date"
            outlined
            :rules="[
              required('une date de fin du contrat valide'),
              isValidDate('DD/MM/YYYY', minDebutFinContrat, maxDateFinContrat),
              isRangeDateValide('Fin'),
            ]"
            label="Fin de Contrat"
            v-model="dateFin"
            :min="minDebutFinContrat"
            :max="maxDateFinContrat"
          />
        </v-col>
      </v-row>
    </v-form>

    <!-- pop up de fornisseur actuelle -->
    <v-dialog v-model="openModalFornisseurActuelle" persistent max-width="80%">
      <v-card>
        <v-toolbar :color="$colors[0]" class="text-white" dark>
          <span class="text-h6 pl-0">Informations Fournisseur</span>
        </v-toolbar>
        <v-form ref="formActuelle" class="pa-4">
          <v-row class="ma-0 pa-0">
            <v-col cols="11" sm="12" md="12" lg="12" xl="12" class="ma-0 pa-0">
              <v-subheader class="ma-0">Type de fournisseur :</v-subheader>
              <v-divider class="ma-0 pa-0"></v-divider>
              <v-row>
                <v-col cols="11" sm="5" md="5" lg="5" xl="5">
                  <v-radio-group v-model="autreFournisseur.typeFournisseur" row>
                    <v-radio
                      v-if="
                        listCompteurSelectedComputedFournisseurActuelle.includes(
                          autreFournisseur.numCompteur
                        ) || editFournisseur == true
                      "
                      :color="$colors[2]"
                      label="Fournisseur actuel"
                      value="Actuelle"
                      class="v-radio"
                    ></v-radio>
                    <v-radio
                      :color="$colors[2]"
                      label="Nouveau fournisseur"
                      value="AuFrn"
                      class="v-radio"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="3">
              <v-select
                v-if="
                  autreFournisseur.typeFournisseur == 'Actuelle' &&
                  editFournisseur == false
                "
                v-model="autreFournisseur.numCompteur"
                outlined
                :items="listCompteurSelectedComputedFournisseurActuelle"
                @change="numCompteurChanged"
                :rules="[required('une N° Compteur')]"
              ></v-select>
              <v-select
                v-if="
                  autreFournisseur.typeFournisseur == 'Actuelle' &&
                  editFournisseur == true
                "
                v-model="autreFournisseur.numCompteur"
                outlined
                :items="listCompteurSelected"
                @change="numCompteurChanged"
                :rules="[required('une N° Compteur')]"
              ></v-select>
              <v-select
                v-if="autreFournisseur.typeFournisseur == 'AuFrn'"
                v-model="autreFournisseur.numCompteur"
                outlined
                :items="listCompteurSelected"
                @change="numCompteurChanged"
                :rules="[required('une N° Compteur')]"
              ></v-select>
            </v-col>

            <v-col cols="11" sm="12" md="12" lg="12" xl="12" class="ma-0 pa-0">
              <v-subheader class="ma-0"
                >Informations du fournisseur :</v-subheader
              >
              <v-col class="pa-0" cols="11" sm="5" md="5" lg="5" xl="5">
                <v-radio-group v-model="autreFournisseur.turpeInclus" row>
                  <v-radio
                    :color="$colors[2]"
                    label="Turpe Inclus"
                    value="oui"
                    class="v-radio"
                  ></v-radio>
                  <v-radio
                    :color="$colors[2]"
                    label="Turpe non Inclus"
                    value="non"
                    class="v-radio"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-divider class="ma-0 pa-0"></v-divider>
            </v-col>
            <v-col class="pl-0" cols="11" sm="3" md="2" lg="2" xl="2">
              <v-select
                required
                outlined
                :rules="[required('un fournisseur valide')]"
                v-model="autreFournisseur.fournisseur"
                :items="optionsFournisseurs"
                label="Nom fournisseur"
              ></v-select>
            </v-col>
            <v-col
              class="pl-0"
              cols="11"
              sm="3"
              md="2"
              lg="2"
              xl="2"
              v-if="autreFournisseur.typeOffre != 'pré-écrêté'"
            >
              <v-select
                required
                outlined
                :rules="[required(`un type d'offre valide`), rules.Length]"
                v-model="autreFournisseur.typeOffre"
                :items="['Arenh', 'Fixe', 'pré-écrêté']"
                label="type d'offre"
              ></v-select>
            </v-col>
            <v-col
              class="pl-0"
              cols="11"
              sm="3"
              md="2"
              lg="2"
              xl="2"
              v-if="autreFournisseur.typeOffre == 'pré-écrêté'"
            >
              <v-select
                required
                outlined
                :rules="[required(`un type d'offre valide`), rules.Length]"
                v-model="autreFournisseur.typeOffre"
                :items="['Arenh', 'Fixe', 'pré-écrêté']"
                label="type d'offre"
              ></v-select>
            </v-col>
            <v-col
              class="pl-0"
              cols="11"
              sm="3"
              md="2"
              lg="2"
              xl="2"
              v-if="autreFournisseur.typeOffre == 'pré-écrêté'"
            >
              <v-checkbox
                v-model="autreFournisseur.Classique"
                label="Classique"
                value="Classique"
              ></v-checkbox>
            </v-col>
            <v-col
              class="pl-0"
              cols="11"
              sm="3"
              md="2"
              lg="2"
              xl="2"
              v-if="
                autreFournisseur.typeOffre == 'pré-écrêté' &&
                autreFournisseur.Classique.indexOf('Classique') == -1
              "
            >
              <v-text-field
                required
                outlined
                type="number"
                :rules="[
                  required(`un valeur de pré-écrêté valide`),
                  rules.Length,
                  rules.pourcentageEcrete,
                ]"
                v-model="autreFournisseur.preEcrete"
                label="Valeur pré-écrêté"
                suffix="%"
              ></v-text-field>
            </v-col>
            <v-col class="pl-0" cols="11" sm="3" md="2" lg="2" xl="2">
              <v-text-field
                type="number"
                step="1"
                required
                outlined
                suffix="mois"
                v-model="autreFournisseur.DUREE"
                label="Durée de contrat"
                :rules="[
                  required(`une durée de contrat valide`),
                  rules.isPositiveNumb,
                ]"
              ></v-text-field>
            </v-col>

            <!---------------------------------------------------------------------------------------------------------->
            
            <v-col class="pt-0 pl-0" cols="12" v-if="autreFournisseur.fournisseur=='JPME' && autreFournisseur.parametreCompteur=='C4'">
              <v-switch
                v-model="jpmeFileOuiNon"
                :label="`Récupérer les données JPME à partir d'un PDF :`"
                color="secondary"
                hide-details
                dense
                inset
              ></v-switch>
              <div class="mt-5" v-if="autreFournisseur.fournisseur=='JPME' && autreFournisseur.parametreCompteur=='C4' && jpmeFileOuiNon==true">
                  <v-row cols="12"> 
                    <v-col cols="12">
                      <v-form ref="formJPMEFileUpload">
                        <v-text-field
                          v-model="margeJpme"
                          type="number"
                          label="Marge"
                          step="0.001"
                          min="0"
                          dense
                          outlined
                          style="max-width: 200px;"
                          :rules="[required('une marge'), rules.margeMaxMinJpme]"
                        ></v-text-field>
                        <div>
                          <div 
                          v-if="filePdfJpme" 
                          style="
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: space-between;
                            gap: 15px;
                            width: 100%;
                            background-color: #edf1f3;
                            border: 1px solid #d8d8d8;
                            border-radius: 4px;
                            padding: 15px 15px;
                          ">
                            <p class="mb-0">{{ filePdfJpme.name }}</p>
                            <v-icon @click="annulerDonnerJpme" >mdi-close</v-icon>
                          </div>
                          <FileUpload v-else @files-uploaded="handleFileUpload"/>
                        </div>
                        <div class="d-flex flex-row mt-4 mb-4" style="gap:10px">
                          <button 
                          @click.prevent="annulerDonnerJpme" 
                          style="
                            font-size: 14px;
                            font-weight: 600;
                            border: 1px solid #9E9E9E; 
                            color: #000000; 
                            border-radius: 4px;
                            padding:8px 24px;
                            height: 40px;
                          ">
                            Annuler
                          </button>
                          <button 
                          @click.prevent="getDonnerJpme" 
                          style="
                            font-size: 14px;
                            background-color: #103a5e; 
                            color: #fff; 
                            border-radius: 4px;
                            padding:8px 24px;
                            height: 40px;
                          ">
                            Récupérer les données JPME 
                          </button>
                        </div>
                      </v-form>
                    </v-col>
                  </v-row>
                  <div v-if="resultatJpmeApi.result == true">
                    <div class="jpme-prices-wrapper">
                      <div v-for="(item, index) in resultatJpmeApi.data" :key="index" class="jpme-price-card">
                        <div class="jpme-price-item">
                          <v-select
                          v-model="item.typeOffre"
                          :items="['Fixe', 'Arenh']"
                          label="Type Offre"
                          hide-details="auto"
                          dense
                          filled
                          :rules="[required(`un type d'offre valide`), rules.Length]"
                          />
                        </div>
                        <div class="jpme-price-item">
                          <v-text-field
                            v-model="item.DUREE"
                            filled
                            hide-details="auto"
                            dense
                            label="DUREE"
                            :rules="[required('une DUREE valide')]"
                          ></v-text-field>
                        </div>
                        <div class="jpme-price-item">
                          <v-text-field
                            v-model="item.prixHPH"
                            filled
                            hide-details="auto"
                            dense
                            label="HPH"
                            :rules="[required('une Prix (HPH) valide')]"
                          ></v-text-field>
                        </div>
                        <div class="jpme-price-item">
                          <v-text-field
                            v-model="item.prixHCH"
                            filled
                            hide-details="auto"
                            dense
                            label="HCH"
                            :rules="[required('une Prix (HCH) valide')]"
                          ></v-text-field>
                        </div>
                        <div class="jpme-price-item">
                          <v-text-field
                            v-model="item.prixHPE"
                            filled
                            hide-details="auto"
                            dense
                            label="HPE"
                            :rules="[required('une Prix (HPE) valide')]"
                          ></v-text-field>
                        </div>
                        <div class="jpme-price-item">
                          <v-text-field
                            v-model="item.prixHCE"
                            filled
                            hide-details="auto"
                            dense
                            label="HCE"
                            :rules="[required('une Prix (HCE) valide')]"
                          ></v-text-field>
                        </div>
                        <div class="jpme-price-item">
                          <v-text-field
                            v-model="item.CEE"
                            filled
                            hide-details="auto"
                            dense
                            label="CEE"
                            :rules="[required('une (CEE) valide')]"
                          ></v-text-field>
                        </div>
                        <div class="jpme-price-item">
                          <v-select
                          v-model="item.typeCapa"
                          :items="['COEF', 'CAPA', 'CAPAINCLUS']"
                          label="Type Capa"
                          hide-details="auto"
                          dense
                          filled
                          :rules="[required(`un type capa`), rules.Length]"
                          />
                        </div>
                        <template v-if="item.typeCapa!='CAPAINCLUS'">
                          <div class="jpme-price-item">
                            <v-text-field
                              v-model="item.capaHPH"
                              filled
                              hide-details="auto"
                              dense
                              label="Capa HPH"
                            :rules="[required('une Capa (HPH) valide')]"
                            ></v-text-field>
                          </div>
                          <div class="jpme-price-item">
                            <v-text-field
                              v-model="item.capaHCH"
                              filled
                              hide-details="auto"
                              dense
                              label="Capa HCH"
                            :rules="[required('une Capa (HCH) valide')]"
                            ></v-text-field>
                          </div>
                          <div class="jpme-price-item">
                            <v-text-field
                              v-model="item.capaHPE"
                              filled
                              hide-details="auto"
                              dense
                              label="Capa HPE"
                            :rules="[required('une Capa (HPE) valide')]"
                            ></v-text-field>
                          </div>
                          <div class="jpme-price-item">
                            <v-text-field
                              v-model="item.capaHCE"
                              filled
                              hide-details="auto"
                              dense
                              label="Capa HCE"
                            :rules="[required('une Capa (HCE) valide')]"
                            ></v-text-field>
                          </div>
                          <div class="jpme-price-item">
                            <v-text-field
                              v-model="item.ABO"
                              filled
                              hide-details="auto"
                              dense
                              label="ABO"
                            :rules="[required('une (ABO) valide')]"
                            ></v-text-field>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                  <!--<div v-else class="alert alert-warning" role="alert">
                    Erreur api le document on ne peut pas le traiter
                  </div>-->
              </div>
            </v-col>
          </v-row>

          <!-- Prix -->
          <v-row class="ma-0 pa-0" v-if="(autreFournisseur.fournisseur=='JPME' && autreFournisseur.parametreCompteur=='C4' && jpmeFileOuiNon==false) || autreFournisseur.fournisseur!='JPME' || autreFournisseur.parametreCompteur!='C4'">
            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="ma-1 pa-0">
              <v-subheader class="ma-0">Prix du MWh :</v-subheader>
              <v-divider class="ma-0 pa-0"></v-divider>
            </v-col>
            <v-col
              v-if="!!autreFournisseur.numCompteur"
              class="pl-0"
              cols="11"
              sm="3"
              md="2"
              lg="2"
              xl="2"
            >
              <v-text-field
                v-if="autreFournisseur.parametreCompteur == 'C5BASE'"
                type="number"
                step="0.001"
                required
                outlined
                v-model="autreFournisseur.prixBASE"
                label="(BASE)"
                :rules="[required('une valeur valide')]"
              ></v-text-field>
              <v-text-field
                v-if="
                  autreFournisseur.parametreCompteur == 'C3' ||
                  autreFournisseur.parametreCompteur == 'C4' ||
                  autreFournisseur.parametreCompteur == 'C5C4'
                "
                type="number"
                step="0.001"
                suffix="€"
                required
                outlined
                v-model="autreFournisseur.prixHPH"
                label="(HPH)"
                :rules="[required('un PRIX (HPH) valide')]"
              ></v-text-field>
              <v-text-field
                v-if="autreFournisseur.parametreCompteur == 'C5HP'"
                type="number"
                step="0.001"
                suffix="€"
                required
                outlined
                v-model="autreFournisseur.prixHP"
                label="(HP)"
                :rules="[required('un PRIX (HP) valide')]"
              ></v-text-field>
            </v-col>

            <v-col
              class="pl-0"
              cols="11"
              sm="3"
              md="2"
              lg="2"
              xl="2"
              v-if="
                autreFournisseur.parametreCompteur == 'C3' ||
                autreFournisseur.parametreCompteur == 'C5HP' ||
                autreFournisseur.parametreCompteur == 'C4' ||
                autreFournisseur.parametreCompteur == 'C5C4'
              "
            >
              <v-text-field
                v-if="
                  autreFournisseur.parametreCompteur == 'C3' ||
                  autreFournisseur.parametreCompteur == 'C4' ||
                  autreFournisseur.parametreCompteur == 'C5C4'
                "
                type="number"
                step="0.001"
                suffix="€"
                required
                outlined
                v-model="autreFournisseur.prixHCH"
                label="(HCH)"
                :rules="[required('un PRIX (HCH) valide')]"
              ></v-text-field>
              <v-text-field
                v-if="autreFournisseur.parametreCompteur == 'C5HP'"
                type="number"
                step="0.001"
                suffix="€"
                required
                outlined
                v-model="autreFournisseur.prixHC"
                label="(HC)"
                :rules="[required('un PRIX (HC) valide')]"
              ></v-text-field>
            </v-col>

            <v-col
              class="pl-0"
              cols="11"
              sm="3"
              md="2"
              lg="2"
              xl="2"
              v-if="
                autreFournisseur.parametreCompteur == 'C3' ||
                autreFournisseur.parametreCompteur == 'C4' ||
                autreFournisseur.parametreCompteur == 'C5C4'
              "
            >
              <v-text-field
                v-if="
                  autreFournisseur.parametreCompteur == 'C3' ||
                  autreFournisseur.parametreCompteur == 'C4' ||
                  autreFournisseur.parametreCompteur == 'C5C4'
                "
                type="number"
                step="0.001"
                suffix="€"
                required
                outlined
                v-model="autreFournisseur.prixHPE"
                label="(HPE)"
                :rules="[required('un PRIX (HPE) valide')]"
              ></v-text-field>
            </v-col>

            <v-col
              class="pl-0"
              cols="11"
              sm="3"
              md="2"
              lg="2"
              xl="2"
              v-if="
                autreFournisseur.parametreCompteur == 'C3' ||
                autreFournisseur.parametreCompteur == 'C4' ||
                autreFournisseur.parametreCompteur == 'C5C4'
              "
            >
              <v-text-field
                v-if="
                  autreFournisseur.parametreCompteur == 'C3' ||
                  autreFournisseur.parametreCompteur == 'C4' ||
                  autreFournisseur.parametreCompteur == 'C5C4'
                "
                type="number"
                step="0.001"
                suffix="€"
                required
                outlined
                v-model="autreFournisseur.prixHCE"
                label="(HCE)"
                :rules="[required('un PRIX (HCE) valide')]"
              ></v-text-field>
            </v-col>

            <v-col
              class="pl-0"
              cols="11"
              sm="3"
              md="2"
              lg="2"
              xl="2"
              v-if="autreFournisseur.parametreCompteur == 'C3'"
            >
              <v-text-field
                v-if="autreFournisseur.parametreCompteur == 'C3'"
                type="number"
                step="0.001"
                required
                outlined
                v-model="autreFournisseur.prixPOINTE"
                suffix="€"
                label="(P)"
                :rules="[required('un PRIX (P) valide')]"
              ></v-text-field>
            </v-col>
            <v-col class="pl-0" cols="11" sm="3" md="2" lg="2" xl="2">
              <v-text-field
                type="number"
                step="0.001"
                required
                outlined
                suffix="€"
                v-model="autreFournisseur.CEE"
                label="CEE"
                :rules="[required('un CEE valide')]"
              ></v-text-field>
            </v-col>

            <v-col class="pl-0" cols="11" sm="3" md="2" lg="2" xl="2">
              <v-text-field
                type="number"
                step="0.001"
                required
                outlined
                suffix="€"
                v-model="autreFournisseur.ABO"
                label="Abonnement mensuel"
                :rules="[required('une valeur valide')]"
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- coef capa -->
          <v-row v-if="!!autreFournisseur.numCompteur && (autreFournisseur.fournisseur=='JPME' && autreFournisseur.parametreCompteur=='C4' && jpmeFileOuiNon==false) || autreFournisseur.fournisseur!='JPME' || autreFournisseur.parametreCompteur!='C4'" class="ma-0 pa-0">
            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="ma-1 pa-0">
              <v-subheader class="ma-0"
                >Informations sur la capacité :</v-subheader
              >
              <v-divider class="ma-0 pa-0"></v-divider>
            </v-col>

            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="ma-1 pa-0">
              <v-radio-group
                v-model="autreFournisseur.typeCapa"
                row
                class="ma-0 pa-0"
              >
                <v-radio
                  :color="$colors[2]"
                  label="Coût capacité €/MWh"
                  value="CAPA"
                ></v-radio>
                <v-radio
                  :color="$colors[2]"
                  label="Coefficient capacité €/MWh"
                  value="COEF"
                ></v-radio>
                <v-radio
                  :color="$colors[2]"
                  label="CAPA Inclus"
                  value="CAPAINCLUS"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col
              class="pl-0"
              cols="11"
              sm="3"
              md="2"
              lg="2"
              xl="2"
              v-if="autreFournisseur.typeCapa != 'CAPAINCLUS'"
            >
              <v-text-field
                v-if="autreFournisseur.parametreCompteur == 'C5BASE'"
                type="number"
                step="0.001"
                suffix="€"
                required
                outlined
                v-model="autreFournisseur.capaBASE"
                label="(BASE)"
                :rules="[required('une valeur valide')]"
              ></v-text-field>
              <v-text-field
                v-if="
                  autreFournisseur.parametreCompteur == 'C3' ||
                  autreFournisseur.parametreCompteur == 'C4' ||
                  autreFournisseur.parametreCompteur == 'C5C4'
                "
                type="number"
                step="0.001"
                suffix="€"
                required
                outlined
                v-model="autreFournisseur.capaHPH"
                label="(HPH)"
                :rules="[required('un CAPA (HPH) valide')]"
              ></v-text-field>
              <v-text-field
                v-if="autreFournisseur.parametreCompteur == 'C5HP'"
                type="number"
                step="0.001"
                suffix="€"
                required
                outlined
                v-model="autreFournisseur.capaHP"
                label="(HP)"
                :rules="[required('un CAPA (HP) valide')]"
              ></v-text-field>
            </v-col>

            <v-col
              class="pl-0"
              cols="11"
              sm="3"
              md="2"
              lg="2"
              xl="2"
              v-if="
                autreFournisseur.typeCapa != 'CAPAINCLUS' &&
                (autreFournisseur.parametreCompteur == 'C3' ||
                  autreFournisseur.parametreCompteur == 'C4' ||
                  autreFournisseur.parametreCompteur == 'C5C4' ||
                  autreFournisseur.parametreCompteur == 'C5HP')
              "
            >
              <v-text-field
                v-if="
                  autreFournisseur.parametreCompteur == 'C3' ||
                  autreFournisseur.parametreCompteur == 'C4' ||
                  autreFournisseur.parametreCompteur == 'C5C4'
                "
                type="number"
                step="0.001"
                suffix="€"
                required
                outlined
                v-model="autreFournisseur.capaHCH"
                label="(HCH)"
                :rules="[rules.required('un CAPA (HCH) valide')]"
              ></v-text-field>
              <v-text-field
                v-if="autreFournisseur.parametreCompteur == 'C5HP'"
                type="number"
                step="0.001"
                suffix="€"
                required
                outlined
                v-model="autreFournisseur.capaHC"
                label="(HC)"
                :rules="[rules.required('un CAPA (HC) valide')]"
              ></v-text-field>
            </v-col>

            <v-col
              class="pl-0"
              cols="11"
              sm="3"
              md="2"
              lg="2"
              xl="2"
              v-if="
                autreFournisseur.typeCapa != 'CAPAINCLUS' &&
                (autreFournisseur.parametreCompteur == 'C3' ||
                  autreFournisseur.parametreCompteur == 'C4' ||
                  autreFournisseur.parametreCompteur == 'C5C4')
              "
            >
              <v-text-field
                v-if="
                  autreFournisseur.parametreCompteur == 'C3' ||
                  autreFournisseur.parametreCompteur == 'C4' ||
                  autreFournisseur.parametreCompteur == 'C5C4'
                "
                type="number"
                step="0.001"
                suffix="€"
                required
                outlined
                v-model="autreFournisseur.capaHPE"
                label="(HPE)"
                :rules="[rules.required('un CAPA (HPE) valide')]"
              ></v-text-field>
            </v-col>

            <v-col
              class="pl-0"
              cols="11"
              sm="3"
              md="2"
              lg="2"
              xl="2"
              v-if="
                autreFournisseur.typeCapa != 'CAPAINCLUS' &&
                (autreFournisseur.parametreCompteur == 'C3' ||
                  autreFournisseur.parametreCompteur == 'C4' ||
                  autreFournisseur.parametreCompteur == 'C5C4')
              "
            >
              <v-text-field
                v-if="
                  autreFournisseur.parametreCompteur == 'C3' ||
                  autreFournisseur.parametreCompteur == 'C4' ||
                  autreFournisseur.parametreCompteur == 'C5C4'
                "
                type="number"
                step="0.001"
                suffix="€"
                required
                outlined
                v-model="autreFournisseur.capaHCE"
                label="(HCE)"
                :rules="[rules.required('un CAPA (HCE) valide')]"
              ></v-text-field>
            </v-col>

            <v-col
              class="pl-0"
              cols="11"
              sm="3"
              md="2"
              lg="2"
              xl="2"
              v-if="
                autreFournisseur.typeCapa != 'CAPAINCLUS' &&
                autreFournisseur.parametreCompteur == 'C3'
              "
            >
              <v-text-field
                v-if="autreFournisseur.parametreCompteur == 'C3'"
                type="number"
                step="0.001"
                suffix="€"
                required
                outlined
                v-model="autreFournisseur.capaPOINTE"
                label="(P)"
                :rules="[rules.required('un CAPA (P) valide')]"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
        <v-card-actions>
          <v-row class="ma-2" justify="space-between">
            <div>
              <v-btn
                v-if="editFournisseur == false"
                @click="resetFournisseurActuelle"
                color="error"
                text
              >
                Supprimer et Fermer
              </v-btn>
            </div>
            <div>
              <v-btn
                color="error"
                class="marginBtn ml-5"
                text
                @click="closeModalHandleFournisseur"
              >
                Fermer
              </v-btn>
              <v-btn
                :color="$colors[0]"
                class="marginBtn ml-5 text-white"
                v-if="editFournisseur == true"
                @click="AjouterFournisseur(indexFournisseurEdited)"
              >
                Modifier
              </v-btn>
              <v-btn
                :color="$colors[0]"
                class="marginBtn ml-5 text-white"
                v-if="editFournisseur == false"
                @click="()=> jpmeFileOuiNon ? AjouterFournisseurJpmePdf() : AjouterFournisseur(-1)"
              >
                Ajouter
              </v-btn>
            </div>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- les deux bottion pricipale -->
    <v-row class="mb-12" v-if="enedisRecherche">
      <!-- affichage des donner du fornisseur actuelle -->
      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="12"
        xl="12"
        class="ma-0 pa-0"
        v-if="tousLesAutreFournisseur.length > 0"
      >
        <v-subheader class="ml-3"
          >Informations des autres Fournisseurs :</v-subheader
        >
        <v-divider class="mx-4 pa-0"></v-divider>
      </v-col>
      <v-expansion-panels
        class="ma-3"
        v-if="tousLesAutreFournisseur.length > 0"
      >
        <v-expansion-panel
          v-for="(item, index) in tousLesAutreFournisseur"
          :key="index"
        >
          <v-expansion-panel-header hide-actions class="responsiveClass">
            <v-btn
              max-width="50"
              icon
              plain
              color="error"
              @click.stop="supprimerFournisseur(index)"
            >
              <v-icon center>mdi-delete </v-icon>
            </v-btn>
            <v-btn
              max-width="50"
              class="reponsiveBtn"
              icon
              plain
              @click.stop="editFournisseurMethod(index)"
            >
              <v-icon :color="$colors[0]">mdi-pencil </v-icon>
            </v-btn>
            <v-subheader
              class="ma-0 d-flex flex-row justify-space-between"
              v-if="item.typeFournisseur == 'Actuelle'"
            >
              <div>
                Informations du fournisseur actuel :
                <b class="pl-2">{{ item.fournisseur }}</b>
              </div>
              <span style="font-size: 0.92rem"
                >Compteur : {{ item.numCompteur }}</span
              >
            </v-subheader>

            <v-subheader
              class="ma-0 d-flex flex-row justify-space-between"
              v-else
            >
              <div>
                Informations du nouveau fournisseur :
                <b class="pl-2">{{ item.fournisseur }}</b>
              </div>
              <span style="font-size: 0.92rem"
                >Compteur : {{ item.numCompteur }}</span
              >
            </v-subheader>
          </v-expansion-panel-header>
          <v-expansion-panel-content
            style="background-color: rgb(245, 245, 245)"
            class="pa-4"
          >
            <v-row justify="space-between">
              <v-col>
                <p class="mb-8 font-weight-bold" style="font-size: 18px">
                  Informations Générales
                </p>
                <p>
                  <span class="grey--text mb-0">Fournisseur : </span
                  ><span style="color: #103a5e; font-weight: bold">{{
                    item.fournisseur
                  }}</span>
                </p>
                <p>
                  <span class="grey--text mb-0">TURPE INCLUS : </span
                  ><span style="color: #103a5e; font-weight: bold">{{
                    item.turpeInclus
                  }}</span>
                </p>
                <p>
                  <span class="grey--text mb-0">Durée de contrat : </span
                  ><span style="color: #103a5e; font-weight: bold"
                    >{{ item.DUREE }} mois</span
                  >
                </p>
              </v-col>
              <v-col>
                <p class="mb-8 font-weight-bold" style="font-size: 18px">
                  Coût du MWh
                </p>
                <div v-if="item.parametreCompteur == 'C5BASE'">
                  <p>
                    <span class="grey--text mb-2">BASE : </span>
                    <span style="color: #103a5e; font-weight: bold"
                      >{{ item.prixBASE }}€</span
                    >
                  </p>
                </div>
                <div v-if="item.parametreCompteur == 'C5HP'">
                  <p>
                    <span class="grey--text mb-2">HP : </span>
                    <span style="color: #103a5e; font-weight: bold"
                      >{{ item.prixHP }}€</span
                    >
                  </p>
                  <p>
                    <span class="grey--text mb-2">HC : </span>
                    <span style="color: #103a5e; font-weight: bold"
                      >{{ item.prixHC }}€</span
                    >
                  </p>
                </div>
                <div
                  v-if="
                    item.parametreCompteur == 'C3' ||
                    item.parametreCompteur == 'C4' ||
                    item.parametreCompteur == 'C5C4'
                  "
                >
                  <p>
                    <span class="grey--text mb-2">HCH : </span>
                    <span style="color: #103a5e; font-weight: bold"
                      >{{ item.prixHCH }}€</span
                    >
                  </p>
                  <p>
                    <span class="grey--text mb-2">HPH : </span>
                    <span style="color: #103a5e; font-weight: bold"
                      >{{ item.prixHPH }}€</span
                    >
                  </p>
                </div>
                <div v-if="item.parametreCompteur == 'C3'">
                  <p>
                    <span class="grey--text mb-2">POINTE : </span>
                    <span style="color: #103a5e; font-weight: bold"
                      >{{ item.prixPOINTE }}€</span
                    >
                  </p>
                </div>
                <div
                  v-if="
                    item.parametreCompteur == 'C3' ||
                    item.parametreCompteur == 'C4' ||
                    item.parametreCompteur == 'C5C4'
                  "
                >
                  <p>
                    <span class="grey--text mb-2">HCE : </span>
                    <span style="color: #103a5e; font-weight: bold"
                      >{{ item.prixHCE }}€</span
                    >
                  </p>
                  <p>
                    <span class="grey--text mb-2">HPE : </span
                    ><span style="color: #103a5e; font-weight: bold"
                      >{{ item.prixHPE }}€</span
                    >
                  </p>
                </div>
                <div>
                  <p>
                    <span class="grey--text mb-2">CEE : </span>
                    <span style="color: #103a5e; font-weight: bold"
                      >{{ item.CEE }}€</span
                    >
                  </p>
                  <p>
                    <span class="grey--text mb-2">Abonnement mensuel : </span>
                    <span style="color: #103a5e; font-weight: bold"
                      >{{ item.ABO }}€</span
                    >
                  </p>
                </div>
              </v-col>

              <v-col>
                <p class="mb-8 font-weight-bold" style="font-size: 18px">
                  Informations sur la Capacité
                </p>
                <div v-if="item.typeCapa != 'CAPAINCLUS'">
                  <div class="mb-0" v-if="item.parametreCompteur == 'C5BASE'">
                    <p>
                      <span class="grey--text mb-2">BASE : </span>
                      <span style="color: #103a5e; font-weight: bold"
                        >{{ item.capaBASE }}€</span
                      >
                    </p>
                  </div>
                  <div class="mb-0" v-if="item.parametreCompteur == 'C5HP'">
                    <p>
                      <span class="grey--text mb-2">HP : </span>
                      <span style="color: #103a5e; font-weight: bold"
                        >{{ item.capaHP }}€</span
                      >
                    </p>
                  </div>
                  <div class="mb-0" v-if="item.parametreCompteur == 'C5HP'">
                    <p>
                      <span class="grey--text mb-2">HC : </span>
                      <span style="color: #103a5e; font-weight: bold"
                        >{{ item.capaHC }}€</span
                      >
                    </p>
                  </div>
                  <div
                    class="mb-0"
                    v-if="
                      item.parametreCompteur == 'C3' ||
                      item.parametreCompteur == 'C4' ||
                      item.parametreCompteur == 'C5C4'
                    "
                  >
                    <p>
                      <span class="grey--text mb-2">HPH : </span>
                      <span style="color: #103a5e; font-weight: bold"
                        >{{ item.capaHPH }}€</span
                      >
                    </p>
                  </div>
                  <div
                    class="mb-0"
                    v-if="
                      item.parametreCompteur == 'C3' ||
                      item.parametreCompteur == 'C4' ||
                      item.parametreCompteur == 'C5C4'
                    "
                  >
                    <p>
                      <span class="grey--text mb-2">HCH : </span>
                      <span style="color: #103a5e; font-weight: bold"
                        >{{ item.capaHCH }}€</span
                      >
                    </p>
                  </div>
                </div>
                <div
                  v-if="
                    item.typeCapa != 'CAPAINCLUS' &&
                    (item.parametreCompteur == 'C3' ||
                      item.parametreCompteur == 'C4' ||
                      item.parametreCompteur == 'C5C4')
                  "
                >
                  <div>
                    <p>
                      <span class="grey--text mb-2">HPE : </span>
                      <span style="color: #103a5e; font-weight: bold"
                        >{{ item.capaHPE }}€</span
                      >
                    </p>
                  </div>
                  <div>
                    <p>
                      <span class="grey--text mb-2">HCE : </span>
                      <span style="color: #103a5e; font-weight: bold"
                        >{{ item.capaHCE }}€</span
                      >
                    </p>
                  </div>
                </div>
                <div
                  v-if="
                    item.typeCapa != 'CAPAINCLUS' &&
                    item.parametreCompteur == 'C3'
                  "
                >
                  <p>
                    <span class="grey--text mb-2">POINTE : </span
                    >{{ item.capaPOINTE }}€
                  </p>
                </div>
                <p class="mt-4">
                  <span class="grey--text">Type des valeurs de CAPA : </span>
                  <span
                    style="color: #103a5e; font-weight: bold"
                    v-if="item.typeCapa == 'CAPA'"
                    >Coût</span
                  >
                  <span
                    style="color: #103a5e; font-weight: bold"
                    v-if="item.typeCapa == 'COEF'"
                    >Coefficient</span
                  >
                  <span
                    style="color: #103a5e; font-weight: bold"
                    v-if="item.typeCapa == 'CAPAINCLUS'"
                    >CAPA Inclus</span
                  >
                </p>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <div class="d-flex flex-row mt-4" v-if="enedisRecherche">
        <v-btn
          @click="resetForm"
          depressed
          large
          class="mx-2"
          elevation="1"
          width="75%"
        >
          Réinitialiser
        </v-btn>
        <v-btn
          depressed
          elevation="1"
          large
          @click="calculer"
          class="mx-2 text-white"
          :color="$colors[0]"
          width="70%"
        >
          Calculer
        </v-btn>
      </div>
    </v-row>
  </div>
</template>
<script>
import moment from "moment";
import axios from "axios";
import loadingComponent from "../../ui/loading-component.vue";
import FileUpload from "./components/FileUpload.vue";

export default {
  name: "FourmulaireDeCotation",
  components: { loadingComponent, FileUpload },
  props: {
    isHistorique: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      margeJpme: 0,
      jpmeFileOuiNon: false,
      filePdfJpme: null,
      resultatJpmeApi: {
        result: false,
      },
      toutLesDonneesEnedis: {},
      dataEnedisComplexParCompteur: {},
      dataAutreFournisseurComplexParCompteur: {},
      listHeaders: [
        "Compteur",
        "Options Tarifaire",
        "Puissance Souscrite",
        "HPH/HP/BASE",
        "HCH/HC",
        "HPE",
        "HCE",
        "POINTE",
        "Consommation Globale",
        "Mois/Enedis",
      ],
      parametreCompteur: "",
      marge: "",
      dateDebut: "",
      dateFin: "",
      optionTarifaire: "",
      listBodyArray: [],
      listCompteurSelected: [],
      compteurPDL: 0,
      editFournisseur: false,
      indexFournisseurEdited: -1,
      errorDateDeFin: false,
      nbrMonth: 0,
      dialog: false,
      loading: false,
      error: false,
      messageDialogeSucces: "",
      messageDialogeError: "",
      errorEnedis: false,
      openModalFornisseurActuelle: false,
      vraiSiret: false,
      resultatEnedisMesure: false,
      resultatEnedisTechnique: false,
      enedisRecherche: false,
      siret: "",
      idHistorique: "",
      valueRechercheSiret: "",
      listSociete: [{ data: "", disable: true }],
      listCompteur: [],
      parametreCompteur: "",
      scoreEllipro: "",
      scoreCreditSafe: "",
      listProposition: [],
      optionsTarifaires: [
        "BTINFCU4",
        "BTINFCUST",
        "BTINFMU4",
        "BTINFMUDT",
        "BTINFLU",
        "BTSUPCU4",
        "BTSUPLU4",
        "HTACU5",
        "HTACUPM5",
        "HTALU5",
        "HTALUPM5",
      ],

      listDonnerCotation: [],

      tousLesAutreFournisseur: [],
      defaulttousLesAutreFournisseur: [],
      autreFournisseur: {
        parametreCompteur: "",
        typeFournisseur: "AuFrn",
        Classique: ["Classique"],
        preEcrete: "0",
        turpeInclus: "non",
        fournisseur: null,
        typeOffre: "",
        prixHPH: null,
        prixHCH: null,
        prixHPE: null,
        prixHCE: null,
        prixHP: null,
        prixHC: null,
        prixBASE: null,
        prixPOINTE: null,
        typeCapa: "CAPA",
        capaHPH: null,
        capaHCH: null,
        capaHPE: null,
        capaHCE: null,
        capaHP: null,
        capaHC: null,
        capaBASE: null,
        capaPOINTE: null,
        CEE: null,
        ABO: null,
        DUREE: null,
      },
      defaultAutreFournisseur: {
        parametreCompteur: "",
        typeFournisseur: "AuFrn",
        Classique: ["Classique"],
        preEcrete: "0",
        turpeInclus: "non",
        fournisseur: null,
        typeOffre: "",
        prixHPH: null,
        prixHCH: null,
        prixHPE: null,
        prixHCE: null,
        prixHP: null,
        prixHC: null,
        prixBASE: null,
        prixPOINTE: null,
        typeCapa: "CAPA",
        capaHPH: null,
        capaHCH: null,
        capaHPE: null,
        capaHCE: null,
        capaHP: null,
        capaHC: null,
        capaBASE: null,
        capaPOINTE: null,
        CEE: null,
        ABO: null,
        DUREE: null,
      },
      listCompteurSelectedComputedFournisseurActuelle: [],
      fournisseurActuelle: false,
      optionsFournisseurs: [
        "Alpiq",
        "ALSEN",
        "Alterna",
        "Antargaz",
        "Axpo",
        "BARRY",
        "BULB",
        "BUTAGAZ",
        "CDISCOUNT",
        "Dyneff",
        "Edenkia",
        "EDF",
        "EDS",
        "EDSB",
        "Ekwateur",
        'Ekivolt',
        "Elecocite",
        "Electricite_de_provence",
        "Electricite_de_savoie",
        "Electricite_de_Strasbourg",
        "ELMY",
        "Enalp",
        "Endesa",
        "Enercoop",
        "Energem",
        "ENERGIE_DICI",
        "Engie",
        "ENI",
        "Enovos",
        "Gaz_de_Bordeaux",
        "GazelEnergie",
        "Gazprom",
        "GEDIA",
        "GEG",
        "GREEN_YELLOW",
        "HAPPE",
        "IBERDROLA",
        "ILEK",
        "JPME",
        "la_bellenergie",
        "LECLERCQ",
        "LLUM",
        "LUCIA",
        "MEGA",
        "MET-France",
        "mint-energie",
        "NatGas",
        "OHM",
        "OVO",
        "Picoty",
        "PLUM",
        "Primeo",
        "Proxelia",
        "Redeéo",
        "SaveEnergies",
        "Selia",
        "SEFE",
        "SOWEE",
        "Synelva",
        "TDE",
        "Urban_Solar",
        "Vattenfall",
        "Volterres",
        "Wekiwi",
        "yeli-xs",
      ],
      //debut rules

      rules: {
        isPositiveNumb: (v) =>
          /^[0-9]\d*$/.test(v) ||
          "La valeur doit etre un entier strictement supérieur à 0",
        required(fieldName) {
          return (value) =>{
            if(typeof value == 'number'){
              return value >= 0 || `Veuillez saisir ${fieldName}`;
            }else{
              return (value?.toString().length > 0 && !!value) || `Veuillez saisir ${fieldName}`;
            }
          }
        },
        siretLength: (v) =>
          v.length == 14 || "Le siret doit comporter 14 chiffres",
        isMonthCorrect: (v) =>
          (v >= 1 && v <= 12) || "Le mois doit etre compris entre 1 et 12 ",
        isNumber: (v) =>
          v.match(/^[0-9]+$/) != null || "Ce champ n'accepte que les chiffres",
        compteurElecLength: (v) =>
          v.length == 14 || "Le RAE / PDL est une suite de 14 chiffres",
        isGazCompteur: (v) =>
          v.match(/^(GI)[0-9]{6}$/) ||
          (v.length == 14 && v.match(/^[0-9]+$/) != null) ||
          "Le numéro PCE/ PDL doit commencer par (GI) ou comporter 14 chiffres",
        phoneLength: (v) =>
          v.length == 10 ||
          "Le numéro de téléphone est une suite de 10 chiffres",
        phoneisNumber: (v) =>
          v.match(/^[0-9]+$/) != null || "Ce champ n'accepte que les chiffres",
        margeMaxMin: (v) =>
          (v <= 25 && v >= 2) || "La marge doit être comprise entre 2 et 25",
        margeMaxMinJpme: (v) =>
          (v >= 0) || "La marge doit être superieur à 0",
        pourcentageEcrete: (v) =>
          (v <= 100 && v >= 1) ||
          "La valeur de pré-écrêté doit être comprise entre 1 et 100",
        Length: (v) => v.length > 0 || "Veuillez saisir un type d'offre",
        isBetweenRangeNumber: (v) =>
          (v >= 2 && v <= 25) ||
          "La valeur saisie doit etre comprise entre 2 et 25",

        emailRules: (v) => (!!v && /.+@.+\.+.+/.test(v)) || "E-mail invalide",
      },
      required(fieldName) {
        return (value) =>{
            if(typeof value == 'number'){
              return value >= 0 || `Veuillez saisir ${fieldName}`;
            }else{
              return (value?.toString().length > 0 && !!value) || `Veuillez saisir ${fieldName}`;
            }
          }
      },
      minDebutContrat: new Date().toISOString().substr(0, 10),
      minDebutFinContrat: new Date().toISOString().substr(0, 10),
      maxDateFinContrat: new Date(
        new Date().getFullYear() + 5 + "-" + 12 + "-" + 31
      )
        .toISOString()
        .substr(0, 10),
      maxDateDebutContrat: new Date(
        new Date().getFullYear() + 5 + "-" + 11 + "-30"
      )
        .toISOString()
        .substr(0, 10),
    };
  },
  watch: {
    marge: function (newValue) {
      if (newValue != null)
        for (const element of this.listDonnerCotation) {
          element.marge = newValue;
        }
    },
    dateDebut: function (newValue) {
      if (newValue != null)
        for (const element of this.listDonnerCotation) {
          element.dateDebut = newValue;
        }
    },
    dateFin: function (newValue) {
      if (newValue != null)
        for (const element of this.listDonnerCotation) {
          element.dateFin = newValue;
        }
    },
    listCompteurSelected: function (newValue) {
      let array = [];
      if (this.tousLesAutreFournisseur.length == 0) {
        array = [...newValue];
        this.listCompteurSelectedComputedFournisseurActuelle = array;
      } else {
        for (let i = 0; i < newValue.length; i++) {
          let ispresent = this.tousLesAutreFournisseur.some((el) => {
            return el.numCompteur == this.newValue[i];
          });
          let isPresentActuelle = this.tousLesAutreFournisseur.some(
            (el) =>
              el.numCompteur == this.newValue[i] &&
              el.typeFournisseur == "Actuelle"
          );
          if (ispresent == false || isPresentActuelle == false) {
            array.push(this.newValue[i]);
          }
        }
        this.listCompteurSelectedComputedFournisseurActuelle = array;
      }
    },
    tousLesAutreFournisseur: {
      handler: function (newValue) {
        let array = [];
        for (let i = 0; i < this.listCompteurSelected.length; i++) {
          if (this.tousLesAutreFournisseur.length == 0) {
            array.push(this.listCompteurSelected[i]);
          } else {
            let ispresent = this.tousLesAutreFournisseur.some((el) => {
              return el.numCompteur == this.listCompteurSelected[i];
            });
            let isPresentActuelle = this.tousLesAutreFournisseur.some(
              (el) =>
                el.numCompteur == this.listCompteurSelected[i] &&
                el.typeFournisseur == "Actuelle"
            );
            if (ispresent == false || isPresentActuelle == false) {
              array.push(this.listCompteurSelected[i]);
            }
          }
        }
        this.listCompteurSelectedComputedFournisseurActuelle = array;
      },
      deep: true,
    },
  },
  created() {
    try {
      this.valueRechercheSiret = this.$route.query.siret;
      this.siret = this.$route.query.siret;
      this.listSociete = [this.siret];
    } catch (error) {
      console.log(error);
    }
    if (this.isHistorique) {
      this.idHistorique = this.$route.query.idHistorique;
      this.getHistoriqueInitialisation();
    }
  },
  methods: {
    AjouterFournisseurJpmePdf() {
      if (!(this.resultatJpmeApi?.data?.length > 0)) {
        this.$toast.error("Remplir les donner de prix avant l'ajout");
        // stop the function and don't complete the others instructions
        return;
      } 
      if (this.$refs.formActuelle.validate() && this.resultatJpmeApi["data"].length > 0) {
        this.tousLesAutreFournisseur = this.tousLesAutreFournisseur.concat(this.resultatJpmeApi["data"]);
        this.resetFournisseurActuelle();
        this.$refs.formActuelle.resetValidation();
        this.openModalFornisseurActuelle = false;
        this.editFournisseur = false;
        this.autreFournisseur.numCompteur = "";
        this.annulerDonnerJpme();
        this.margeJpme = null;
        this.jpmeFileOuiNon = false
      }
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      this.filePdfJpme = file;
      // Vous pouvez maintenant travailler avec `this.filePdfJpme`
    },
    annulerDonnerJpme() {
      this.filePdfJpme = null;
      this.resultatJpmeApi = {
        result: false,
        data: [],
      }
    },
    getDonnerJpme() {
      if (this.$refs.formJPMEFileUpload.validate()) {
        var formData = new FormData();
          formData.append("PDFfile", this.filePdfJpme);
          formData.append("parametreCompteur", this.autreFournisseur.parametreCompteur);
          formData.append("optionTarifaire",  this.optionTarifaire);
          formData.append("marge", this.margeJpme);
          formData.append("numCompteur",  this.autreFournisseur.numCompteur);
          formData.append("typeFournisseur",  this.autreFournisseur.typeFournisseur);
          formData.append("token", this.getToken("token"));
          axios({
            url: process.env.VUE_APP_URL_API_CLIENT + "executerJpme/",
            method: "POST",
            data: formData,
          })
          .then((res) => {
              this.resultatJpmeApi = res.data;
          }).catch((err)=>{
            this.$toast.error("Erreur : Veuillez vérifier votre fichier PDF.");
          })
      }
    },
    changeOptionCalculeBtinf(obj, newParam) {
      var tmpListe = [];
      if (this.defaulttousLesAutreFournisseur.length > 0) {
        for (var i = 0; i < this.defaulttousLesAutreFournisseur.length; i++) {
          if (
            this.defaulttousLesAutreFournisseur[i]["numCompteur"] ==
            obj.numCompteur
          ) {
            if (
              this.defaulttousLesAutreFournisseur[i]["parametreCompteur"] ==
              newParam
            ) {
              tmpListe.push(this.defaulttousLesAutreFournisseur[i]);
            }
          }
        }
        this.tousLesAutreFournisseur = [...tmpListe];
      }
      try {
        this.toutLesDonneesEnedis =
          this.dataEnedisComplexParCompteur[obj.numCompteur][newParam];
        this.toutLesDonneesEnedis.parametreDeCompteur = obj.parametreCompteur;
        const index = this.listDonnerCotation.findIndex(
          (item) => item.numCompteur === obj.numCompteur
        );
        this.listDonnerCotation.splice(index, 1);
        const newDonneDeCotation = this.remplireDataCotationComplex(
          obj.numCompteur,
          false,
          this.dataAutreFournisseurComplexParCompteur[obj.numCompteur][newParam]
        );
        this.listDonnerCotation.splice(index, 0, newDonneDeCotation);
      } catch (err) {
        console.log(err);
      }
      this.renitialiserLaListeDeResultat();
    },
    openModalFornisseurActuelleFnc() {
      this.$refs.formGeneral.validate();
      if (this.$refs.formGeneral.validate()) {
        this.openModalFornisseurActuelle = true;
        this.margeJpme = this.marge;
      }
    },
    validateSelectionCompteur(value) {
      if (value.length < 1 || value.length > 10) {
        return "Vous ne pouvez pas sélectionner plus de 10 éléments.";
      }
      return true;
    },
    isDisabledMesures(compteurLine) {
      if (compteurLine?.resultatEnedis?.mesuresInfo[0].nbrMonth >= 8) {
        return true;
      } else {
        return false;
      }
    },
    SupprimerCompteurPDL() {
      if (this.listCompteurSelected.length > 1) {
        this.tousLesAutreFournisseur.forEach((element, index) => {
          if (
            this.listCompteurSelected[this.listCompteurSelected.length - 1] ==
            element.numCompteur
          ) {
            this.tousLesAutreFournisseur.splice(index, 1);
          }
        });

        this.listCompteurSelected.splice(
          this.listCompteurSelected.length - 1,
          1
        );
      }

      this.compteurPDL--;
    },
    incrementCompteurPDL() {
      if (this.listCompteur[this.compteurPDL] != this.listCompteur.slice(-1)) {
        this.compteurPDL++;
        this.listCompteurSelected[this.compteurPDL] =
          this.listCompteur[this.compteurPDL];
      }
    },
    getHistoriqueInitialisation() {
      const formData = new FormData();
      formData.append("historique_id", this.idHistorique);
      formData.append("token", this.getToken("token"));
      let arrayTemp = [];
      axios({
        url:
          process.env.VUE_APP_URL_API_CLIENT +
          "getPreparationDesDoneesDeCotationElecHistoriques/",
        method: "POST",
        data: formData,
      }).then((responseData) => {

        if (responseData.data.result === "permission") {
          localStorage.setItem("vueAfficher", "particulier");
          localStorage.removeItem("vendeurName");
          localStorage.removeItem("typeUser");
          localStorage.removeItem("token");
          this.$router.push("/login");
        } else if (responseData.data.result == true) {
          let dataSociete = responseData.data.dataSociete;
          this.vraiSiret = true;
          this.scoreEllipro = dataSociete.scoreEllipro;
          this.scoreCreditSafe = dataSociete.scoreCreditSafe;
          this.listCompteur = responseData.data.listeCompteurSelected;
          this.listCompteurSelected = responseData.data.listeCompteurSelected;
          this.siret = dataSociete.siret;
          this.listDonnerCotation = responseData.data.listDonnerCotation;
          this.enedisRecherche = true
          this.dateDebut = responseData.data.infoDate.dateDebut;
          this.dateFin = responseData.data.infoDate.dateFin;
          this.marge = responseData.data.infoDate.marge;
          let propositionCompteur = [];
          for (let i = 0; i < this.listCompteurSelected.length; i++) {
            let listProposition = responseData.data.propositionCompteur[this.listCompteurSelected[i]];
            propositionCompteur.push({
              NumCompteur: this.listCompteurSelected[i],
              resultat: listProposition,
            });
          }

          this.tousLesAutreFournisseur = responseData.data.fournisseurAuxiliaireElec
          for (var j = 0; j < this.tousLesAutreFournisseur.lenght; j++) {
            if (
              this.tousLesAutreFournisseur[j]["typeOffre"].indexOf(
                "pré-écrêté"
              ) != -1
            ) {
              if (
                this.tousLesAutreFournisseur[j]["typeOffre"].indexOf("%") !=
                -1
              ) {
                this.tousLesAutreFournisseur[j]["Classique"] = [""];
                this.tousLesAutreFournisseur[j]["preEcrete"] =
                  this.tousLesAutreFournisseur[j]["typeOffre"]
                    .split("pré-écrêté")[1]
                    .split("%")[0]
                    .replace(" ", "");
              } else {
                this.tousLesAutreFournisseur[j]["Classique"] = ["Classique"];
                this.tousLesAutreFournisseur[j]["preEcrete"] = "0";
              }
              this.tousLesAutreFournisseur[j]["typeOffre"] = "pré-écrêté";
            }
          }

          this.$emit("sendDataToParent", {
            infosCompteur: responseData.data.propositionCompteur,
            donneDeCotation: null,
            optionTarifaire: null,
            parametreCompteur: null,
            Propositions: propositionCompteur, //Propositions: res.data.result,
            SocieteData: dataSociete,
            enedisData: {},
            numCompteur: "",
            turpeData: "",
            taxeData: "",
            typeCompteur: "",
            fournisseurActuelle: "",
            selected: [],
            listCompteur: [...this.listCompteurSelected],
            donnerCompteur: {},
          });

          this.dialog = false;
          this.loading = false;
        } else if (responseData.data.result == false) {
          this.vraiSiret = false;
          this.loading = false;
          this.messageDialogeError =
            "Vérifiez que votre siret/raison social soit valide et que vous disposez d'une demande de cotation déjà acceptée";
          this.error = true;
        }
      });
    },
    renitialiserLaListeDeResultat() {
      this.$emit("sendDataToParent", {
        donneDeCotation: [],
        optionTarifaire: "",
        parametreCompteur: "",
        Propositions: [],
        SocieteData: "",
        enedisData: "",
        numCompteur: "",
        turpeData: "",
        taxeData: "",
        typeCompteur: "",
        fournisseurActuelle: "",
        selected: [],
        listCompteur: [],
        donnerCompteur: null,
      });
    },
    resetForm() {
      this.listBodyArray.splice(0);
      this.listCompteurSelected.splice(0);
      this.compteurPDL = 0;
      this.renitialiserLaListeDeResultat();
      this.fournisseurActuelle = false;
      //donner generale
      this.openModalFornisseurActuelle = false;
      this.vraiSiret = false;
      this.resultatEnedisMesure = false;
      this.resultatEnedisTechnique = false;
      this.enedisRecherche = false;
      this.siret = "";
      this.listSociete = [];
      this.listCompteur = [];
      this.parametreCompteur = "";
      this.scoreEllipro = "";
      this.scoreCreditSafe = "";
      //donner de cotation
      this.marge = "";
      this.dateDebut = "";
      this.dateFin = "";
      //fornisseur actuelle

      this.openModalFornisseurActuelle = false;
      this.autreFournisseur.typeFournisseur = "AuFrn";
      this.autreFournisseur.turpeInclus = "non";
      this.autreFournisseur.Classique = ["Classique"];
      this.autreFournisseur.preEcrete = "0";
      this.autreFournisseur.fournisseur = null;
      this.autreFournisseur.typeOffre = "";
      this.autreFournisseur.prixHPH = null;
      this.autreFournisseur.prixHCH = null;
      this.autreFournisseur.prixHPE = null;
      this.autreFournisseur.prixHCE = null;
      this.autreFournisseur.prixHP = null;
      this.autreFournisseur.prixHC = null;
      this.autreFournisseur.prixBASE = null;
      this.autreFournisseur.prixPOINTE = null;
      this.autreFournisseur.typeCapa = "CAPA";
      this.autreFournisseur.capaHPH = null;
      this.autreFournisseur.capaHCH = null;
      this.autreFournisseur.capaHPE = null;
      this.autreFournisseur.capaHCE = null;
      this.autreFournisseur.capaHP = null;
      this.autreFournisseur.capaHC = null;
      this.autreFournisseur.capaBASE = null;
      this.autreFournisseur.capaPOINTE = null;
      this.autreFournisseur.CEE = null;
      this.autreFournisseur.ABO = null;
      this.autreFournisseur.DUREE = null;
      this.autreFournisseur.parametreCompteur = "";
    },
    resetFournisseurActuelle() {
      this.renitialiserLaListeDeResultat();
      this.$refs.formActuelle.resetValidation();
      this.editFournisseur = false;
      this.indexFournisseurEdited = -1;
      this.autreFournisseur.typeFournisseur = "AuFrn";
      this.autreFournisseur.numCompteur = "";
      this.autreFournisseur.turpeInclus = "non";
      this.autreFournisseur.Classique = ["Classique"];
      this.autreFournisseur.preEcrete = "0";
      this.autreFournisseur.fournisseur = null;
      this.autreFournisseur.typeOffre = "";
      this.autreFournisseur.prixHPH = null;
      this.autreFournisseur.prixHCH = null;
      this.autreFournisseur.prixHPE = null;
      this.autreFournisseur.prixHCE = null;
      this.autreFournisseur.prixHP = null;
      this.autreFournisseur.prixHC = null;
      this.autreFournisseur.prixBASE = null;
      this.autreFournisseur.prixPOINTE = null;
      this.autreFournisseur.typeCapa = "CAPA";
      this.autreFournisseur.capaHPH = null;
      this.autreFournisseur.capaHCH = null;
      this.autreFournisseur.capaHPE = null;
      this.autreFournisseur.capaHCE = null;
      this.autreFournisseur.capaHP = null;
      this.autreFournisseur.capaHC = null;
      this.autreFournisseur.capaBASE = null;
      this.autreFournisseur.capaPOINTE = null;
      this.autreFournisseur.CEE = null;
      this.autreFournisseur.ABO = null;
      this.autreFournisseur.DUREE = null;
      this.autreFournisseur.parametreCompteur = "";
      this.openModalFornisseurActuelle = false;
      this.jpmeFileOuiNon = false;
    },
    dateFormatSlach(date) {
      return date.split("-").reverse().join("/");
    },
    dateFormatMinus(date) {
      return date.split("/").reverse().join("-");
    },
    isRangeDateValide(date) {
      if (
        this.dateFin != "" &&
        this.dateDebut != "" &&
        this.dateFin < this.dateDebut
      ) {
        switch (date) {
          case "Début":
            return "la date de début doit etre inférieure à la date de fin";
            break;
          case "Fin":
            return "la date de fin doit etre supérieur à la date de début";
            break;

          default:
            break;
        }
      }
    },
    isValidDate(formatDate, minDate, maxDate) {
      return (date) => {
        var tmpdate = this.dateFormatMinus(date);
        if (!date) {
          return "Veuillez saisir une date valide";
        }
        if (moment(tmpdate).isBefore(minDate)) {
          return `La date doit être supérieure  au ${this.dateFormatSlach(
            minDate
          )}.`;
        }
        if (moment(tmpdate).isAfter(maxDate)) {
          return `La date limite de fin de contrat est: ${this.dateFormatSlach(
            maxDate
          )}.`;
        }
        return true;
      };
    },
    changeOptionTarifaire(obj) {
      var optionTarifaire = obj.optionTarifaire;
      ////////////////
      if (
        obj.resultatEnedisMesure == false &&
        obj.resultatEnedisTechnique == false
      ) {
        if (optionTarifaire == "BTINFMUDT") {
          obj.parametreCompteur = "C5HP";
          obj.typeTraitementCompteur = "simple";
        }
        if (optionTarifaire == "BTINFLU" || optionTarifaire == "BTINFCUST") {
          obj.parametreCompteur = "C5BASE";
          obj.typeTraitementCompteur = "simple";
        }
        if (["BTSUPCU4", "BTSUPLU4"].includes(optionTarifaire)) {
          obj.parametreCompteur = "C4";
          obj.typeTraitementCompteur = "simple";
        }
        if (optionTarifaire == "BTINFCU4") {
          obj.parametreCompteur = "C5BASE";
          obj.typeTraitementCompteur = "complex";
        }
        if (optionTarifaire == "BTINFMU4") {
          obj.parametreCompteur = "C5HP";
          obj.typeTraitementCompteur = "complex";
        }
        if (
          ["HTACU5", "HTACUPM5", "HTALU5", "HTALUPM5"].includes(optionTarifaire)
        ) {
          obj.parametreCompteur = "C3";
          obj.typeTraitementCompteur = "simple";
        }
      }
      if (optionTarifaire != "BTINFCU4" && optionTarifaire != "BTINFMU4") {
        var tmpListe = [];
        if (this.defaulttousLesAutreFournisseur.length > 0) {
          for (var i = 0; i < this.defaulttousLesAutreFournisseur.length; i++) {
            if (
              this.defaulttousLesAutreFournisseur[i]["numCompteur"] ==
              obj.numCompteur
            ) {
              if (
                this.defaulttousLesAutreFournisseur[i]["parametreCompteur"] ==
                obj.parametreCompteur
              ) {
                tmpListe.push(this.defaulttousLesAutreFournisseur[i]);
              }
            }
          }
          this.tousLesAutreFournisseur = tmpListe;
        }
      } else {
        for (var i = 0; i < this.tousLesAutreFournisseur.length; i++) {
          if (
            this.tousLesAutreFournisseur[i]["numCompteur"] == obj.numCompteur
          ) {
            this.tousLesAutreFournisseur[i]["numCompteur"] = [];
          }
        }
        this.toutLesDonneesEnedis =
          this.dataEnedisComplexParCompteur[obj.numCompteur][
            obj.parametreCompteur
          ];
        this.toutLesDonneesEnedis.parametreDeCompteur = obj.parametreCompteur;
        try {
          const index = this.listDonnerCotation.findIndex(
            (item) => item.numCompteur === obj.numCompteur
          );
          this.listDonnerCotation.splice(index, 1);
          const newDonneDeCotation = this.remplireDataCotationComplex(
            obj.numCompteur,
            false,
            this.dataAutreFournisseurComplexParCompteur[obj.numCompteur][
              obj.parametreCompteur
            ]
          );
          this.listDonnerCotation.splice(index, 0, newDonneDeCotation);
        } catch (err) {
          console.log(err);
        }
      }
      this.renitialiserLaListeDeResultat();
    },
    getListeCompteurs() {
      this.compteurPDL = 0;
      this.$refs.formeSiret.validate();
      if (this.$refs.formeSiret.validate()) {
        this.dialog = true;
        this.loading = true;
        this.error = false;
        this.dateDebut = "";
        this.dateFin = "";
        this.marge = "";
        this.listCompteur = [];
        this.listCompteurSelected = [];
        this.listDonnerCotation = [];
        this.tousLesAutreFournisseur = [];
        this.defaulttousLesAutreFournisseur = [];
        this.messageDialogeError = "";
        this.messageDialogeSucces = "";
        this.siret = this.valueRechercheSiret;
        this.renitialiserLaListeDeResultat();
        var formData = new FormData();
        formData.append("value", this.siret);
        formData.append("token", this.getToken("token"));
        axios({
          url:
            process.env.VUE_APP_URL_API_CLIENT + "getListCompteurElecParSiret/",
          method: "POST",
          data: formData,
        })
          .then((res) => {
            const responseData = res.data;
            if (responseData.result === "permission") {
              localStorage.setItem("vueAfficher", "particulier");
              localStorage.removeItem("vendeurName");
              localStorage.removeItem("typeUser");
              localStorage.removeItem("token");
              this.$router.push("/login");
            } else if (responseData.result == true) {
              if (responseData.compteur.length <= 0) {
                this.loading = false;
                this.messageDialogeError =
                  "Vous n'avez aucun compteur associé à ce siret/raison sociale";
                this.error = true;
              }
              const data = responseData;
              this.vraiSiret = true;
              this.scoreEllipro = responseData.scoreEllipro;
              this.scoreCreditSafe = responseData.scoreCreditSafe;
              this.listCompteur = responseData.compteur;
              this.siret = responseData.siret;

              this.dialog = false;
              this.loading = false;
            } else if (responseData.result == false) {
              this.vraiSiret = false;
              this.loading = false;
              this.messageDialogeError =
                "Vérifiez que votre siret/raison social soit valide et que vous disposez d'une demande de cotation déjà acceptée";
              this.error = true;
            }
          })
          .catch((error) => {
            this.loading = false;
            this.messageDialogeError =
              "Il semble qu'une erreur soit survenue ! Veuillez réessayer plus tard ";
            this.error = true;
            console.log(error);
          });
      }
    },

    async getAllConsommation() {
      if (!this.$refs.formGetConsommation.validate()) {
        return false;
      }
      this.marge = "";
      this.optionTarifaire = "";
      this.parametreCompteur = "";
      this.autreFournisseur.parametreCompteur = "";
      this.listBodyArray = [];
      this.listDonnerCotation = [];
      this.tousLesAutreFournisseur = [];
      this.defaulttousLesAutreFournisseur = [];
      this.renitialiserLaListeDeResultat();
      this.enedisRecherche = false;
      for (let i = 0; i < this.listCompteurSelected.length; i++) {
        var formData = new FormData();
        formData.append("numCompteur", this.listCompteurSelected[i]);
        formData.append("token", this.getToken("token"));
        await axios({
          url:
            process.env.VUE_APP_URL_API_CLIENT +
            "getToutLesDonneesParCompteurEnedis/",
          method: "POST",
          data: formData,
        })
          .then(async (res) => {
            if (res.data.toutLesDonneesEnedis.result === "permission") {
              localStorage.removeItem("vendeurName");
              localStorage.removeItem("typeUser");
              localStorage.removeItem("token");
              this.$router.push("/login");
            }
            if (
              res.data.toutLesDonneesEnedis.typeCompteurTraitemant == "simple"
            ) {
              this.toutLesDonneesEnedis = res.data.toutLesDonneesEnedis;
              let data = res.data;
              await this.remplireDataCotationSimple(
                this.listCompteurSelected[i],
                data
              );
            } else {
              let FRNaux = [];
              this.dataEnedisComplexParCompteur[this.listCompteurSelected[i]] =
                {};
              this.dataAutreFournisseurComplexParCompteur[
                this.listCompteurSelected[i]
              ] = {};
              this.dataAutreFournisseurComplexParCompteur[
                this.listCompteurSelected[i]
              ]["C5C4"] = res.data.fournisseurAuxiliaireElec.filter(
                (item) => item.parametreCompteur === "C5C4"
              );
              this.dataAutreFournisseurComplexParCompteur[
                this.listCompteurSelected[i]
              ]["C5HP"] = res.data.fournisseurAuxiliaireElec.filter(
                (item) => item.parametreCompteur === "C5HP"
              );
              this.dataAutreFournisseurComplexParCompteur[
                this.listCompteurSelected[i]
              ]["C5BASE"] = res.data.fournisseurAuxiliaireElec.filter(
                (item) => item.parametreCompteur === "C5BASE"
              );

              this.dataEnedisComplexParCompteur[this.listCompteurSelected[i]][
                "C5C4"
              ] = res.data.toutLesDonneesEnedis.dataEnedis[0];
              this.dataEnedisComplexParCompteur[this.listCompteurSelected[i]][
                "C5HP"
              ] = res.data.toutLesDonneesEnedis.dataEnedis[1];
              this.dataEnedisComplexParCompteur[this.listCompteurSelected[i]][
                "C5BASE"
              ] = res.data.toutLesDonneesEnedis.dataEnedis[2];
              if (
                this.dataEnedisComplexParCompteur[this.listCompteurSelected[i]][
                  "C5C4"
                ].donneTechniqueInfo[0].result == "valide"
              ) {
                let tarif =
                  this.dataEnedisComplexParCompteur[
                    this.listCompteurSelected[i]
                  ]["C5C4"].donneTechniqueInfo[0].data.typeCompteur;
                if (tarif == "BTINFCU4") {
                  this.toutLesDonneesEnedis =
                    res.data.toutLesDonneesEnedis.dataEnedis[2];
                  FRNaux =
                    this.dataAutreFournisseurComplexParCompteur[
                      this.listCompteurSelected[i]
                    ]["C5BASE"];
                } else {
                  this.toutLesDonneesEnedis =
                    res.data.toutLesDonneesEnedis.dataEnedis[1];
                  FRNaux =
                    this.dataAutreFournisseurComplexParCompteur[
                      this.listCompteurSelected[i]
                    ]["C5HP"];
                }
                let data = res.data;
                const newDonneDeCotation =
                  await this.remplireDataCotationComplex(
                    this.listCompteurSelected[i],
                    res.data.autreData,
                    FRNaux
                  );
                this.listDonnerCotation.push(newDonneDeCotation);
              }
            }
          })
          .catch((err) => {
            console.log(err);
            this.enedisRecherche = false;
            this.errorEnedis = false;
            this.parametreCompteur = "";
          });
      }
    },
    remplireDataCotationSimple(numCompteur, data) {
      let arrayTemp = [];
      this.nbrMonth = this.toutLesDonneesEnedis.mesuresInfo[0].nbrMonth;
      if (
        this.toutLesDonneesEnedis.mesuresInfo[0].result == "notValide" &&
        this.toutLesDonneesEnedis.donneTechniqueInfo[0].result == "notValide"
      ) {
        this.errorEnedis = true;
      } else {
        this.errorEnedis = false;
        this.enedisRecherche = true;
      }
      let donneDeCotation = {};

      this.enedisRecherche = true;
      donneDeCotation.resultatEnedis = this.toutLesDonneesEnedis;
      arrayTemp = [...data.fournisseurAuxiliaireElec];
      this.defaulttousLesAutreFournisseur = [...data.fournisseurAuxiliaireElec];
      for (var j = 0; j < this.defaulttousLesAutreFournisseur.lenght; j++) {
        if (
          this.defaulttousLesAutreFournisseur[j]["typeOffre"].indexOf(
            "pré-écrêté"
          ) != -1
        ) {
          if (
            this.defaulttousLesAutreFournisseur[j]["typeOffre"].indexOf("%") !=
            -1
          ) {
            this.defaulttousLesAutreFournisseur[j]["Classique"] = [""];
            this.defaulttousLesAutreFournisseur[j]["preEcrete"] =
              this.defaulttousLesAutreFournisseur[j]["typeOffre"]
                .split("pré-écrêté")[1]
                .split("%")[0]
                .replace(" ", "");
          } else {
            this.defaulttousLesAutreFournisseur[j]["Classique"] = ["Classique"];
            this.defaulttousLesAutreFournisseur[j]["preEcrete"] = "0";
          }
          this.defaulttousLesAutreFournisseur[j]["typeOffre"] = "pré-écrêté";
        }
      }
      if (this.toutLesDonneesEnedis.mesuresInfo[0].result === "valide") {
        for (var j = 0; j < arrayTemp.length; j++) {
          if (arrayTemp[j]["typeOffre"].indexOf("pré-écrêté") != -1) {
            if (arrayTemp[j]["typeOffre"].indexOf("%") != -1) {
              arrayTemp[j]["Classique"] = [""];
              arrayTemp[j]["preEcrete"] = arrayTemp[j]["typeOffre"]
                .split("pré-écrêté")[1]
                .split("%")[0]
                .replace(" ", "");
            } else {
              arrayTemp[j]["Classique"] = ["Classique"];
              arrayTemp[j]["preEcrete"] = "0";
            }
            arrayTemp[j]["typeOffre"] = "pré-écrêté";
          }
          arrayTemp[j].optionTarifaire =
            this.toutLesDonneesEnedis.mesuresInfo[0]["dataParMonth"][0][
              "typeCompteur"
            ];
        }
        this.resultatEnedisMesure = true;
        if ("HP" in this.toutLesDonneesEnedis.mesuresInfo[0].donne) {
          donneDeCotation.CAHP =
            this.toutLesDonneesEnedis.mesuresInfo[0].donne.HP.somme;
        } else {
          donneDeCotation.CAHP = null;
        }
        if ("HC" in this.toutLesDonneesEnedis.mesuresInfo[0].donne) {
          donneDeCotation.CAHC =
            this.toutLesDonneesEnedis.mesuresInfo[0].donne.HC.somme;
        } else {
          donneDeCotation.CAHC = null;
        }
        if ("HPH" in this.toutLesDonneesEnedis.mesuresInfo[0].donne) {
          donneDeCotation.CAHPH =
            this.toutLesDonneesEnedis.mesuresInfo[0].donne.HPH.somme;
        } else {
          donneDeCotation.CAHPH = null;
        }
        if ("HCH" in this.toutLesDonneesEnedis.mesuresInfo[0].donne) {
          donneDeCotation.CAHCH =
            this.toutLesDonneesEnedis.mesuresInfo[0].donne.HCH.somme;
        } else {
          donneDeCotation.CAHCH = null;
        }
        if ("HPE" in this.toutLesDonneesEnedis.mesuresInfo[0].donne) {
          donneDeCotation.CAHPE =
            this.toutLesDonneesEnedis.mesuresInfo[0].donne.HPE.somme;
        } else {
          donneDeCotation.CAHPE = null;
        }
        if ("HCE" in this.toutLesDonneesEnedis.mesuresInfo[0].donne) {
          donneDeCotation.CAHCE =
            this.toutLesDonneesEnedis.mesuresInfo[0].donne.HCE.somme;
        } else {
          donneDeCotation.CAHCE = null;
        }
        if ("Pointe" in this.toutLesDonneesEnedis.mesuresInfo[0].donne) {
          donneDeCotation.CAP =
            this.toutLesDonneesEnedis.mesuresInfo[0].donne.Pointe.somme;
        }
        if ("POINTE" in this.toutLesDonneesEnedis.mesuresInfo[0].donne) {
          donneDeCotation.CAP =
            this.toutLesDonneesEnedis.mesuresInfo[0].donne.POINTE.somme;
        }
        if ("PTE" in this.toutLesDonneesEnedis.mesuresInfo[0].donne) {
          donneDeCotation.CAP =
            this.toutLesDonneesEnedis.mesuresInfo[0].donne.PTE.somme;
        }
        if (
          !("Pointe" in this.toutLesDonneesEnedis.mesuresInfo[0].donne) &&
          !("POINTE" in this.toutLesDonneesEnedis.mesuresInfo[0].donne) &&
          !("PTE" in this.toutLesDonneesEnedis.mesuresInfo[0].donne)
        ) {
          donneDeCotation.CAP = null;
        }
        if ("BASE" in this.toutLesDonneesEnedis.mesuresInfo[0].donne) {
          donneDeCotation.CABASE =
            this.toutLesDonneesEnedis.mesuresInfo[0].donne.BASE.somme;
        } else {
          donneDeCotation.CABASE = null;
        }
      } else {
        this.resultatEnedisMesure = false;
        donneDeCotation.CAHP = null;
        donneDeCotation.CAHC = null;
        donneDeCotation.CAHPH = null;
        donneDeCotation.CAHCH = null;
        donneDeCotation.CAHPE = null;
        donneDeCotation.CAHCE = null;
        donneDeCotation.CAP = null;
        donneDeCotation.CAP = null;
        donneDeCotation.CABASE = null;
      }
      donneDeCotation["parametreCompteur"] = this.toutLesDonneesEnedis.parametreDeCompteur || "";
      if (this.toutLesDonneesEnedis.donneTechniqueInfo[0].result === "valide") {
        this.parametreCompteur = this.toutLesDonneesEnedis.parametreDeCompteur;
        if (
          this.toutLesDonneesEnedis.donneTechniqueInfo[0].data.typeCompteur ===
            "" ||
          this.toutLesDonneesEnedis.donneTechniqueInfo[0].data.typeCompteur ==
            "Résilié"
        ) {
          this.resultatEnedisTechnique = false;
        } else {
          this.resultatEnedisTechnique = true;
        }
        if (
          this.toutLesDonneesEnedis.donneTechniqueInfo[0].data.typeCompteur !=
            "" &&
          this.toutLesDonneesEnedis.donneTechniqueInfo[0].data.kva != ""
        ) {
          donneDeCotation.optionTarifaire =
            this.toutLesDonneesEnedis.donneTechniqueInfo[0].data.typeCompteur;
          donneDeCotation.puissanceSouscrite =
            this.toutLesDonneesEnedis.donneTechniqueInfo[0].data.kva;
        } else {
          donneDeCotation.optionTarifaire = null;
          donneDeCotation.puissanceSouscrite = null;
        }
        // get tout les kva

        donneDeCotation.kvaHp =
          this.toutLesDonneesEnedis.donneTechniqueInfo[0].data?.HP || "";

        donneDeCotation.kvaHc =
          this.toutLesDonneesEnedis.donneTechniqueInfo[0].data?.HC || "";

        donneDeCotation.kvaHce =
          this.toutLesDonneesEnedis.donneTechniqueInfo[0].data?.HCE || "";

        donneDeCotation.kvaHpe =
          this.toutLesDonneesEnedis.donneTechniqueInfo[0].data?.HPE || "";

        donneDeCotation.kvaHch =
          this.toutLesDonneesEnedis.donneTechniqueInfo[0].data?.HCH || "";

        donneDeCotation.kvaHph =
          this.toutLesDonneesEnedis.donneTechniqueInfo[0].data?.HPH || "";

        donneDeCotation.kvaBase =
          this.toutLesDonneesEnedis.donneTechniqueInfo[0].data?.BASE || "";

        donneDeCotation.kvaPte =
          this.toutLesDonneesEnedis.donneTechniqueInfo[0].data?.PTE || "";
      } else {
        this.parametreCompteur = "";
        this.resultatEnedisTechnique = false;
        donneDeCotation.kvaHp = "";

        donneDeCotation.kvaHc = "";

        donneDeCotation.kvaHce = "";

        donneDeCotation.kvaHpe = "";

        donneDeCotation.kvaHch = "";

        donneDeCotation.kvaHph = "";

        donneDeCotation.kvaBase = "";

        donneDeCotation.kvaPte = "";
      }
      if (data.autreData.result == true) {
        const dateDebutFormatted = this.dateFormatMinus(
          data.autreData.dateDebut
        );
        const dateFinFormatted = this.dateFormatMinus(data.autreData.dateFin);
        this.dateDebut = dateDebutFormatted;
        this.dateFin = dateFinFormatted;
        donneDeCotation.dateDebut = dateDebutFormatted;
        donneDeCotation.dateFin = dateFinFormatted;
      }
      if (donneDeCotation.parametreCompteur == "C3") {
        donneDeCotation.sommeConsomation =
          donneDeCotation.CAHCH +
          donneDeCotation.CAHPH +
          donneDeCotation.CAHPE +
          donneDeCotation.CAHCE +
          donneDeCotation.CAP;
      } else if (donneDeCotation.parametreCompteur == "C4") {
        donneDeCotation.sommeConsomation =
          donneDeCotation.CAHCH +
          donneDeCotation.CAHPH +
          donneDeCotation.CAHPE +
          donneDeCotation.CAHCE;
      } else if (donneDeCotation.parametreCompteur == "C5HP") {
        donneDeCotation.sommeConsomation =
          donneDeCotation.CAHC + donneDeCotation.CAHP;
      } else if (donneDeCotation.parametreCompteur == "C5BASE") {
        donneDeCotation.sommeConsomation = donneDeCotation.CABASE || 0;
      }
      donneDeCotation.resultatEnedisTechnique = this.resultatEnedisTechnique;
      donneDeCotation.resultatEnedisMesure = this.resultatEnedisMesure;
      donneDeCotation["numCompteur"] = numCompteur;
      donneDeCotation["typeTraitementCompteur"] = "simple";
      arrayTemp.forEach((element) => {
        this.tousLesAutreFournisseur.push(element);
      });
      this.listDonnerCotation.push(donneDeCotation);
    },
    remplireDataCotationComplex(numCompteur, autreData, FRNaux) {
      let arrayTemp = [];
      this.nbrMonth = this.toutLesDonneesEnedis.mesuresInfo[0].nbrMonth;
      if (
        this.toutLesDonneesEnedis.mesuresInfo[0].result == "notValide" &&
        this.toutLesDonneesEnedis.donneTechniqueInfo[0].result == "notValide"
      ) {
        this.errorEnedis = true;
      } else {
        this.errorEnedis = false;
        this.enedisRecherche = true;
      }
      let donneDeCotation = {};

      this.enedisRecherche = true;
      donneDeCotation.resultatEnedis = this.toutLesDonneesEnedis;
      arrayTemp = [...FRNaux];
      this.defaulttousLesAutreFournisseur = [...FRNaux];
      for (var j = 0; j < this.defaulttousLesAutreFournisseur.lenght; j++) {
        if (
          this.defaulttousLesAutreFournisseur[j]["typeOffre"].indexOf(
            "pré-écrêté"
          ) != -1
        ) {
          if (
            this.defaulttousLesAutreFournisseur[j]["typeOffre"].indexOf("%") !=
            -1
          ) {
            this.defaulttousLesAutreFournisseur[j]["Classique"] = [""];
            this.defaulttousLesAutreFournisseur[j]["preEcrete"] =
              this.defaulttousLesAutreFournisseur[j]["typeOffre"]
                .split("pré-écrêté")[1]
                .split("%")[0]
                .replace(" ", "");
          } else {
            this.defaulttousLesAutreFournisseur[j]["Classique"] = ["Classique"];
            this.defaulttousLesAutreFournisseur[j]["preEcrete"] = "0";
          }
          this.defaulttousLesAutreFournisseur[j]["typeOffre"] = "pré-écrêté";
        }
      }
      if (this.toutLesDonneesEnedis.mesuresInfo[0].result === "valide") {
        for (var j = 0; j < arrayTemp.length; j++) {
          if (arrayTemp[j]["typeOffre"].indexOf("pré-écrêté") != -1) {
            if (arrayTemp[j]["typeOffre"].indexOf("%") != -1) {
              arrayTemp[j]["Classique"] = [""];
              arrayTemp[j]["preEcrete"] = arrayTemp[j]["typeOffre"]
                .split("pré-écrêté")[1]
                .split("%")[0]
                .replace(" ", "");
            } else {
              arrayTemp[j]["Classique"] = ["Classique"];
              arrayTemp[j]["preEcrete"] = "0";
            }
            arrayTemp[j]["typeOffre"] = "pré-écrêté";
          }
          arrayTemp[j].optionTarifaire =
            this.toutLesDonneesEnedis.mesuresInfo[0]["dataParMonth"][0][
              "typeCompteur"
            ];
        }
        this.resultatEnedisMesure = true;
        if ("HP" in this.toutLesDonneesEnedis.mesuresInfo[0].donne) {
          donneDeCotation.CAHP =
            this.toutLesDonneesEnedis.mesuresInfo[0].donne.HP.somme;
        } else {
          donneDeCotation.CAHP = null;
        }
        if ("HC" in this.toutLesDonneesEnedis.mesuresInfo[0].donne) {
          donneDeCotation.CAHC =
            this.toutLesDonneesEnedis.mesuresInfo[0].donne.HC.somme;
        } else {
          donneDeCotation.CAHC = null;
        }
        if ("HPH" in this.toutLesDonneesEnedis.mesuresInfo[0].donne) {
          donneDeCotation.CAHPH =
            this.toutLesDonneesEnedis.mesuresInfo[0].donne.HPH.somme;
        } else {
          donneDeCotation.CAHPH = null;
        }
        if ("HCH" in this.toutLesDonneesEnedis.mesuresInfo[0].donne) {
          donneDeCotation.CAHCH =
            this.toutLesDonneesEnedis.mesuresInfo[0].donne.HCH.somme;
        } else {
          donneDeCotation.CAHCH = null;
        }
        if ("HPE" in this.toutLesDonneesEnedis.mesuresInfo[0].donne) {
          donneDeCotation.CAHPE =
            this.toutLesDonneesEnedis.mesuresInfo[0].donne.HPE.somme;
        } else {
          donneDeCotation.CAHPE = null;
        }
        if ("HCE" in this.toutLesDonneesEnedis.mesuresInfo[0].donne) {
          donneDeCotation.CAHCE =
            this.toutLesDonneesEnedis.mesuresInfo[0].donne.HCE.somme;
        } else {
          donneDeCotation.CAHCE = null;
        }
        if ("Pointe" in this.toutLesDonneesEnedis.mesuresInfo[0].donne) {
          donneDeCotation.CAP =
            this.toutLesDonneesEnedis.mesuresInfo[0].donne.Pointe.somme;
        }
        if ("POINTE" in this.toutLesDonneesEnedis.mesuresInfo[0].donne) {
          donneDeCotation.CAP =
            this.toutLesDonneesEnedis.mesuresInfo[0].donne.POINTE.somme;
        }
        if ("PTE" in this.toutLesDonneesEnedis.mesuresInfo[0].donne) {
          donneDeCotation.CAP =
            this.toutLesDonneesEnedis.mesuresInfo[0].donne.PTE.somme;
        }
        if (
          !("Pointe" in this.toutLesDonneesEnedis.mesuresInfo[0].donne) &&
          !("POINTE" in this.toutLesDonneesEnedis.mesuresInfo[0].donne) &&
          !("PTE" in this.toutLesDonneesEnedis.mesuresInfo[0].donne)
        ) {
          donneDeCotation.CAP = null;
        }
        if ("BASE" in this.toutLesDonneesEnedis.mesuresInfo[0].donne) {
          donneDeCotation.CABASE =
            this.toutLesDonneesEnedis.mesuresInfo[0].donne.BASE.somme;
        } else {
          donneDeCotation.CABASE = null;
        }
      } else {
        this.resultatEnedisMesure = false;
        donneDeCotation.CAHP = null;
        donneDeCotation.CAHC = null;
        donneDeCotation.CAHPH = null;
        donneDeCotation.CAHCH = null;
        donneDeCotation.CAHPE = null;
        donneDeCotation.CAHCE = null;
        donneDeCotation.CAP = null;
        donneDeCotation.CAP = null;
        donneDeCotation.CABASE = null;
      }
      donneDeCotation["parametreCompteur"] = this.toutLesDonneesEnedis.parametreDeCompteur || "";
      if (this.toutLesDonneesEnedis.donneTechniqueInfo[0].result === "valide") {
        this.parametreCompteur = this.toutLesDonneesEnedis.parametreDeCompteur;
        if (
          this.toutLesDonneesEnedis.donneTechniqueInfo[0].data.typeCompteur ===
            "" ||
          this.toutLesDonneesEnedis.donneTechniqueInfo[0].data.typeCompteur ==
            "Résilié"
        ) {
          this.resultatEnedisTechnique = false;
        } else {
          this.resultatEnedisTechnique = true;
        }
        if (
          this.toutLesDonneesEnedis.donneTechniqueInfo[0].data.typeCompteur !=
            "" &&
          this.toutLesDonneesEnedis.donneTechniqueInfo[0].data.kva != ""
        ) {
          donneDeCotation.optionTarifaire =
            this.toutLesDonneesEnedis.donneTechniqueInfo[0].data.typeCompteur;
          donneDeCotation.puissanceSouscrite =
            this.toutLesDonneesEnedis.donneTechniqueInfo[0].data.kva;
        } else {
          donneDeCotation.optionTarifaire = null;
          donneDeCotation.puissanceSouscrite = null;
        }
        // get tout les kva

        donneDeCotation.kvaHp =
          this.toutLesDonneesEnedis.donneTechniqueInfo[0].data?.HP || "";

        donneDeCotation.kvaHc =
          this.toutLesDonneesEnedis.donneTechniqueInfo[0].data?.HC || "";

        donneDeCotation.kvaHce =
          this.toutLesDonneesEnedis.donneTechniqueInfo[0].data?.HCE || "";

        donneDeCotation.kvaHpe =
          this.toutLesDonneesEnedis.donneTechniqueInfo[0].data?.HPE || "";

        donneDeCotation.kvaHch =
          this.toutLesDonneesEnedis.donneTechniqueInfo[0].data?.HCH || "";

        donneDeCotation.kvaHph =
          this.toutLesDonneesEnedis.donneTechniqueInfo[0].data?.HPH || "";

        donneDeCotation.kvaBase =
          this.toutLesDonneesEnedis.donneTechniqueInfo[0].data?.BASE || "";

        donneDeCotation.kvaPte =
          this.toutLesDonneesEnedis.donneTechniqueInfo[0].data?.PTE || "";
      } else {
        this.parametreCompteur = "";
        this.resultatEnedisTechnique = false;
        donneDeCotation.kvaHp = "";

        donneDeCotation.kvaHc = "";

        donneDeCotation.kvaHce = "";

        donneDeCotation.kvaHpe = "";

        donneDeCotation.kvaHch = "";

        donneDeCotation.kvaHph = "";

        donneDeCotation.kvaBase = "";

        donneDeCotation.kvaPte = "";
      }
      if (autreData != false) {
        if (autreData.result == true) {
          const dateDebutFormatted = this.dateFormatMinus(autreData.dateDebut);
          const dateFinFormatted = this.dateFormatMinus(autreData.dateFin);
          this.dateDebut = dateDebutFormatted;
          this.dateFin = dateFinFormatted;
          donneDeCotation.dateDebut = dateDebutFormatted;
          donneDeCotation.dateFin = dateFinFormatted;
          donneDeCotation.marge = this.marge;
        }
      } else {
        donneDeCotation.dateDebut = this.dateDebut;
        donneDeCotation.dateFin = this.dateFin;
        donneDeCotation.marge = this.marge;
      }
      if (donneDeCotation.parametreCompteur == "C3") {
        donneDeCotation.sommeConsomation =
          donneDeCotation.CAHCH +
          donneDeCotation.CAHPH +
          donneDeCotation.CAHPE +
          donneDeCotation.CAHCE +
          donneDeCotation.CAP;
      } else if (
        donneDeCotation.parametreCompteur == "C4" ||
        donneDeCotation.parametreCompteur == "C5C4"
      ) {
        donneDeCotation.sommeConsomation =
          donneDeCotation.CAHCH +
          donneDeCotation.CAHPH +
          donneDeCotation.CAHPE +
          donneDeCotation.CAHCE;
      } else if (donneDeCotation.parametreCompteur == "C5C4") {
        donneDeCotation.sommeConsomation =
          donneDeCotation.CAHCH +
          donneDeCotation.CAHPH +
          donneDeCotation.CAHPE +
          donneDeCotation.CAHCE;
      } else if (donneDeCotation.parametreCompteur == "C5HP") {
        donneDeCotation.sommeConsomation =
          donneDeCotation.CAHC + donneDeCotation.CAHP;
      } else if (donneDeCotation.parametreCompteur == "C5BASE") {
        donneDeCotation.sommeConsomation = donneDeCotation.CABASE || 0;
      }
      donneDeCotation.resultatEnedisTechnique = this.resultatEnedisTechnique;
      donneDeCotation.resultatEnedisMesure = this.resultatEnedisMesure;
      donneDeCotation["numCompteur"] = numCompteur;
      donneDeCotation["typeTraitementCompteur"] = "complex";
      arrayTemp.forEach((element) => {
        this.tousLesAutreFournisseur.push(element);
      });
      return donneDeCotation;
    },
    async getCompteurData() {
      this.dialog = true;
      this.loading = true;
      this.error = false;
      this.messageDialogeError = "";
      this.messageDialogeSucces = "";
      await this.getAllConsommation();
      this.dialog = false;
      this.loading = false;
    },
    calculer() {
      if (
        new Date(this.dateDebut).getTime() >= new Date(this.dateFin).getTime()
      ) {
        this.errorDateDeFin = true;
      } else {
        this.errorDateDeFin = false;
      }
      if (!this.$refs.formGeneral.validate()) {
        return false;
      }
      this.errorDateDeFin = false;
      this.dialog = true;
      this.loading = true;
      this.messageDialogeError = "";
      this.messageDialogeSucces = "";
      this.error = false;
      this.renitialiserLaListeDeResultat();
      this.$refs.formGeneral.validate();
      if (this.$refs.formGeneral.validate()) {
        axios({
          url:
            process.env.VUE_APP_URL_API_CLIENT + "MultipleCalculeCoutProElec/",
          method: "POST",
          data: {
            donneDeCotation: this.listDonnerCotation,
            token: this.getToken("token"),
            parametreCompteur: this.parametreCompteur,
            siret: this.siret,
            autreFournisseur: this.tousLesAutreFournisseur,
          },
        })
          .then((res) => {
            if (res.data.result === "permission") {
              localStorage.setItem("vueAfficher", "particulier");
              localStorage.removeItem("vendeurName");
              localStorage.removeItem("typeUser");
              localStorage.removeItem("token");
              this.$router.push("/login");
            } else if (res.data.result === false) {
              this.error = true;
              this.loading = false;
              console.log(err);
              this.messageDialogeError =
                "une erreur s'est produite lors du calcul du cotation";
            }

            let propositionCompteur = [];
            for (let i = 0; i < this.listCompteurSelected.length; i++) {
              let listProposition = res.data[this.listCompteurSelected[i]];
              propositionCompteur.push({
                NumCompteur: this.listCompteurSelected[i],
                resultat: listProposition,
              });
            }
            this.$emit("sendDataToParent", {
              infosCompteur: res.data,
              donneDeCotation: null,
              optionTarifaire: null,
              parametreCompteur: null,
              Propositions: propositionCompteur, //Propositions: res.data.result,
              SocieteData: res.data.dataSociete,
              enedisData: res.data.dataEnedis,
              numCompteur: res.data.numCompteur,
              turpeData: res.data.dataTurpe,
              taxeData: res.data.dataTaxe,
              typeCompteur: res.data.typeCompteur,
              fournisseurActuelle: res.data.actuelle,
              selected: [],
              listCompteur: [...this.listCompteur], //this.listCompteurSelected, //
              donnerCompteur: res.data.donnerCompteur,
            });
            console.log("emmited");

            this.dialog = false;
            this.loading = false;
          })
          .catch((err) => {
            this.error = true;
            this.loading = false;
            console.log(err);
            this.messageDialogeError =
              "une erreur s'est produite lors du calcul du cotation";
          });
      }
    },
    numCompteurChanged() {
      var itemSelected = this.listDonnerCotation.find(
        (item) => item.numCompteur == this.autreFournisseur.numCompteur
      );
      this.optionTarifaire = itemSelected.optionTarifaire;
      this.parametreCompteur = itemSelected.parametreCompteur;
      this.autreFournisseur.parametreCompteur = itemSelected.parametreCompteur;
    },
    supprimerFournisseur(index) {
      this.renitialiserLaListeDeResultat();
      this.tousLesAutreFournisseur.splice(index, 1);
      this.defaulttousLesAutreFournisseur.splice(index, 1);

      const foundActuelle = this.tousLesAutreFournisseur.some(
        (item) => item.typeFournisseur == "Actuelle"
      );
      if (foundActuelle) {
        this.fournisseurActuelle = true;
      } else {
        this.fournisseurActuelle = false;
      }
    },
    AjouterFournisseur(index = -1) {
      this.renitialiserLaListeDeResultat();
      this.$refs.formActuelle.validate();
      if (this.$refs.formActuelle.validate()) {
        const foundActuelle = this.tousLesAutreFournisseur.some(
          (item) => item.typeFournisseur == "Actuelle"
        );
        const isCurrentActuelle =
          this.autreFournisseur.typeFournisseur == "Actuelle";
        if (foundActuelle || isCurrentActuelle) {
          this.fournisseurActuelle = true;
        } else {
          this.fournisseurActuelle = false;
        }

        if (this.editFournisseur == true) {
          var d = {
            turpeInclus: this.autreFournisseur.turpeInclus,
            typeFournisseur: this.autreFournisseur.typeFournisseur,
            numCompteur: this.autreFournisseur.numCompteur,
            fournisseur: this.autreFournisseur.fournisseur,
            typeOffre: this.autreFournisseur.typeOffre,
            prixHPH: this.autreFournisseur.prixHPH,
            prixHCH: this.autreFournisseur.prixHCH,
            prixHPE: this.autreFournisseur.prixHPE,
            prixHCE: this.autreFournisseur.prixHCE,
            prixHP: this.autreFournisseur.prixHP,
            prixHC: this.autreFournisseur.prixHC,
            prixBASE: this.autreFournisseur.prixBASE,
            prixPOINTE: this.autreFournisseur.prixPOINTE,
            typeCapa: this.autreFournisseur.typeCapa,
            capaHPH: this.autreFournisseur.capaHPH,
            capaHCH: this.autreFournisseur.capaHCH,
            capaHPE: this.autreFournisseur.capaHPE,
            capaHCE: this.autreFournisseur.capaHCE,
            capaHP: this.autreFournisseur.capaHP,
            capaHC: this.autreFournisseur.capaHC,
            capaBASE: this.autreFournisseur.capaBASE,
            capaPOINTE: this.autreFournisseur.capaPOINTE,
            CEE: this.autreFournisseur.CEE,
            ABO: this.autreFournisseur.ABO,
            DUREE: this.autreFournisseur.DUREE,
            parametreCompteur: this.autreFournisseur.parametreCompteur,
          };
          d.optionTarifaire = this.optionTarifaire;
          if (this.autreFournisseur.typeOffre == "pré-écrêté") {
            if (this.autreFournisseur.Classique.indexOf("Classique") != -1) {
              d["typeOffre"] = "pré-écrêté Classique";
            } else {
              d["typeOffre"] =
                this.autreFournisseur.typeOffre +
                " " +
                this.autreFournisseur.preEcrete +
                "%";
            }
          } else {
            d["typeOffre"] = this.autreFournisseur.typeOffre;
          }
          this.tousLesAutreFournisseur.splice(index, 1);
          this.tousLesAutreFournisseur.push(d);
          if (this.autreFournisseur.typeFournisseur == "Actuelle") {
            for (var i = 0; i < this.tousLesAutreFournisseur.length; i++) {
              if (
                this.tousLesAutreFournisseur[i]["numCompteur"] ==
                  this.autreFournisseur["numCompteur"] &&
                i != this.tousLesAutreFournisseur.length - 1 &&
                this.tousLesAutreFournisseur[i]["typeFournisseur"] == "Actuelle"
              ) {
                this.tousLesAutreFournisseur[i]["typeFournisseur"] =
                  "AuFrn";
              }
            }
          }
        } else {
          var d = {
            turpeInclus: this.autreFournisseur.turpeInclus,
            typeFournisseur: this.autreFournisseur.typeFournisseur,
            numCompteur: this.autreFournisseur.numCompteur,
            fournisseur: this.autreFournisseur.fournisseur,
            typeOffre: this.autreFournisseur.typeOffre,
            prixHPH: this.autreFournisseur.prixHPH,
            prixHCH: this.autreFournisseur.prixHCH,
            prixHPE: this.autreFournisseur.prixHPE,
            prixHCE: this.autreFournisseur.prixHCE,
            prixHP: this.autreFournisseur.prixHP,
            prixHC: this.autreFournisseur.prixHC,
            prixBASE: this.autreFournisseur.prixBASE,
            prixPOINTE: this.autreFournisseur.prixPOINTE,
            typeCapa: this.autreFournisseur.typeCapa,
            capaHPH: this.autreFournisseur.capaHPH,
            capaHCH: this.autreFournisseur.capaHCH,
            capaHPE: this.autreFournisseur.capaHPE,
            capaHCE: this.autreFournisseur.capaHCE,
            capaHP: this.autreFournisseur.capaHP,
            capaHC: this.autreFournisseur.capaHC,
            capaBASE: this.autreFournisseur.capaBASE,
            capaPOINTE: this.autreFournisseur.capaPOINTE,
            CEE: this.autreFournisseur.CEE,
            ABO: this.autreFournisseur.ABO,
            DUREE: this.autreFournisseur.DUREE,
            parametreCompteur: this.autreFournisseur.parametreCompteur,
          };
          d.optionTarifaire = this.optionTarifaire;
          if (this.autreFournisseur.typeOffre == "pré-écrêté") {
            if (this.autreFournisseur.Classique.indexOf("Classique") != -1) {
              d["typeOffre"] = "pré-écrêté Classique";
            } else {
              d["typeOffre"] =
                this.autreFournisseur.typeOffre +
                " " +
                this.autreFournisseur.preEcrete +
                "%";
            }
          } else {
            d["typeOffre"] = this.autreFournisseur.typeOffre;
          }
          this.tousLesAutreFournisseur.push(d);
          this.defaulttousLesAutreFournisseur.push(d);
        }
        this.resetFournisseurActuelle();
        this.$refs.formActuelle.resetValidation();
        this.openModalFornisseurActuelle = false;
        this.editFournisseur = false;
        this.autreFournisseur.numCompteur = "";
        this.annulerDonnerJpme();
        this.margeJpme = null;
      }
    },
    editFournisseurMethod(index) {
      this.editFournisseur = true;
      this.indexFournisseurEdited = index;
      var fournisseurToUpdate = this.tousLesAutreFournisseur[index];
      if (fournisseurToUpdate["typeOffre"].indexOf("pré-écrêté") != -1) {
        if (fournisseurToUpdate["typeOffre"].indexOf("%") != -1) {
          fournisseurToUpdate["Classique"] = [""];
          fournisseurToUpdate["preEcrete"] = fournisseurToUpdate["typeOffre"]
            .split("pré-écrêté")[1]
            .split("%")[0]
            .replace(" ", "");
        } else {
          fournisseurToUpdate["Classique"] = ["Classique"];
          fournisseurToUpdate["preEcrete"] = "0";
        }
        fournisseurToUpdate["typeOffre"] = "pré-écrêté";
      }
      this.defaultAutreFournisseur = { ...fournisseurToUpdate };
      this.autreFournisseur = fournisseurToUpdate;

      this.$refs.formGeneral.validate();
      if (this.$refs.formGeneral.validate()) {
        this.openModalFornisseurActuelle = true;
      }
      this.numCompteurChanged();
    },
    closeModalHandleFournisseur() {
      this.annulerDonnerJpme();
      this.jpmeFileOuiNon = false;
      this.openModalFornisseurActuelle = false;
      this.editFournisseur = false;
      this.$refs.formActuelle.resetValidation();
      this.tousLesAutreFournisseur[this.indexFournisseurEdited] =
        this.defaultAutreFournisseur;
      this.defaulttousLesAutreFournisseur[this.indexFournisseurEdited] = {
        ...this.defaultAutreFournisseur,
      };
      this.indexFournisseurEdited = -1;
      this.defaultAutreFournisseur = {
        typeFournisseur: "AuFrn",
        numCompteur: "",
        turpeInclus: "non",
        Classique: ["Classique"],
        preEcrete: "0",
        fournisseur: null,
        typeOffre: "",
        prixHPH: null,
        prixHCH: null,
        prixHPE: null,
        prixHCE: null,
        prixHP: null,
        prixHC: null,
        prixBASE: null,
        prixPOINTE: null,
        typeCapa: "CAPA",
        capaHPH: null,
        capaHCH: null,
        capaHPE: null,
        capaHCE: null,
        capaHP: null,
        capaHC: null,
        capaBASE: null,
        capaPOINTE: null,
        CEE: null,
        ABO: null,
        DUREE: null,
      };
      this.autreFournisseur = this.defaultAutreFournisseur;
    },
    calculeSommeCotation(donneDeCotation) {
      if (donneDeCotation.parametreCompteur == "C3") {
        var hch = 0;
        var hph = 0;
        var hce = 0;
        var hpe = 0;
        var pointe = 0;
        try {
          if (!isNaN(donneDeCotation.CAHCH)) {
            hch = parseFloat(donneDeCotation.CAHCH);
          } else {
            hch = 0;
          }
        } catch (error) {
          hch = 0;
        }
        try {
          if (!isNaN(donneDeCotation.CAHPH)) {
            hph = parseFloat(donneDeCotation.CAHPH);
          } else {
            hph = 0;
          }
        } catch (error) {
          hph = 0;
        }
        try {
          if (!isNaN(donneDeCotation.CAHPE)) {
            hpe = parseFloat(donneDeCotation.CAHPE);
          } else {
            hpe = 0;
          }
        } catch (error) {
          hpe = 0;
        }
        try {
          if (!isNaN(donneDeCotation.CAHCE)) {
            hce = parseFloat(donneDeCotation.CAHCE);
          } else {
            hce = 0;
          }
        } catch (error) {
          hce = 0;
        }
        try {
          if (!isNaN(donneDeCotation.CAP)) {
            pointe = parseFloat(donneDeCotation.CAP);
          } else {
            pointe = 0;
          }
        } catch (error) {
          pointe = 0;
        }
        donneDeCotation.sommeConsomation = hch + hph + hce + hpe + pointe;
      } else if (
        donneDeCotation.parametreCompteur == "C4" ||
        donneDeCotation.parametreCompteur == "C5C4"
      ) {
        var hch = 0;
        var hph = 0;
        var hce = 0;
        var hpe = 0;
        var pointe = 0;
        try {
          if (!isNaN(donneDeCotation.CAHCH)) {
            hch = parseFloat(donneDeCotation.CAHCH);
          } else {
            hch = 0;
          }
        } catch (error) {
          hch = 0;
        }
        try {
          if (!isNaN(donneDeCotation.CAHPH)) {
            hph = parseFloat(donneDeCotation.CAHPH);
          } else {
            hph = 0;
          }
        } catch (error) {
          hph = 0;
        }
        try {
          if (!isNaN(donneDeCotation.CAHPE)) {
            hpe = parseFloat(donneDeCotation.CAHPE);
          } else {
            hpe = 0;
          }
        } catch (error) {
          hpe = 0;
        }
        try {
          if (!isNaN(donneDeCotation.CAHCE)) {
            hce = parseFloat(donneDeCotation.CAHCE);
          } else {
            hce = 0;
          }
        } catch (error) {
          hce = 0;
        }
        donneDeCotation.sommeConsomation = hch + hph + hce + hpe;
      } else if (donneDeCotation.parametreCompteur == "C5HP") {
        var hc = 0;
        var hp = 0;
        try {
          if (!isNaN(donneDeCotation.CAHC)) {
            hc = parseFloat(donneDeCotation.CAHC);
          } else {
            hc = 0;
          }
        } catch (error) {
          hc = 0;
        }
        try {
          if (!isNaN(donneDeCotation.CAHP)) {
            hp = parseFloat(donneDeCotation.CAHP);
          } else {
            hp = 0;
          }
        } catch (error) {
          hp = 0;
        }
        donneDeCotation.sommeConsomation = hc + hp;
      } else if (donneDeCotation.parametreCompteur == "C5BASE") {
        var base = 0;
        try {
          if (!isNaN(donneDeCotation.CABASE)) {
            base = parseFloat(donneDeCotation.CABASE);
          } else {
            base = 0;
          }
        } catch (error) {
          base = 0;
        }
        donneDeCotation.sommeConsomation = base;
      }
    },
  },
  computed: {
    getPropositionSiretOrName() {
      this.listCompteurSelected = [];
      this.listBodyArray = [];
      this.Propositions = [];
      if (this.Propositions.length == 0) {
        this.renitialiserLaListeDeResultat();
      }
      this.listCompteur = [];
      this.enedisRecherche = false;
      this.vraiSiret = false;
      if (this.valueRechercheSiret != "") {
        var formData = new FormData();
        formData.append("siret", this.valueRechercheSiret);
        formData.append("token", this.getToken("token"));
        this.isCompteursLoading = true;
        axios({
          url: process.env.VUE_APP_URL_API_CLIENT + "getPropositionSociete/",
          method: "POST",
          data: formData,
        }).then((res) => {
          this.listSociete = res.data.result;
        });
      } else {
        this.listSociete = [];
      }
      return true;
    },
  },
};
</script>
<style scoped>
::v-deep
  .smallradio
  .v-label {
  font-size: 12px;
  margin: 0;
  padding: 0;
}

.table-compteurs-wrapper {
  overflow: auto;
}
.table-compteurs-wrapper table th,
.table-compteurs-wrapper table td {
  padding-left: 4px;
  padding-right: 4px;
  min-width: 50px;
}
.v-subheader {
  font-size: large;
  font-weight: 300;
  color: black;
  padding: 0px;
}

::v-deep .v-label {
  margin: 0px;
}

@media only screen and (max-width: 600px) {
  .v-subheader {
    font-size: medium;
    margin-top: 10px;
  }
}

@media only screen and (max-width: 1260px) {
  .responsive-margin {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 500px) {
  .responsiveClass {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .reponsiveBtn {
    margin: 0px;
  }

  .responsiveGroupButton {
    display: flex;
    flex-direction: column;
  }

  ::v-deep .marginBtn {
    margin-bottom: 10px !important;
    margin-left: 0px !important;
  }
}

@media screen and (max-width: 510px) {
  .v-subheader {
    font-size: 1em;
  }

  .v-dialog > .v-card > .v-card__title {
    font-size: 0.7em;
  }
}

@media screen and (max-width: 300px) {
  .v-dialog > .v-card > .v-card__title {
    font-size: 0.7em;
    padding-left: 0px;
    padding-right: 0px;
  }
}
</style>
<style scoped>
.jpme-prices-wrapper{
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}
.jpme-price-card{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  width: 100%;
  padding: 20px 20px;
  border-radius: 4px;
  border: 1px solid #d3d3d3;
  box-shadow: 0px 4px 8px 0px #e3e3e3;
}
.jpme-price-block{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  width: 100%;
}
.jpme-price-item{
  width: 140px;
}
</style>